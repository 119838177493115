import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { CSVLink } from "react-csv";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import {
  Row,
  Col,
  Form,
  Button,
  Table,
  Alert,
  Tabs,
  Tab,
  Modal,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Font,
  PDFViewer,
} from "@react-pdf/renderer";
import Card from "../../components/Card/MainCard";
import config from "../../config.js";
import Receipt from "./GeneralReceipt01.js";
import { Link } from "react-router-dom";
import ConfirmationModal from "../ui-elements/basic/ConfirmationModal.js";
import DataTable from "react-data-table-component";
import { PDFContent } from "../../components/Receipts/index.js";
import { Bar } from "react-chartjs-2";
// import 'chart.js/auto';
import { subMonths, startOfMonth, endOfMonth, format } from "date-fns";

const TransactionHistory = () => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [transactionData, setTransactionData] = useState([]);
  const [showReceipt, setShowReceipt] = useState(false);
  const [showReceiptModal, setShowReceiptModal] = useState(false);
  const [transactionId, setTransactionId] = useState(null);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const environment =
    window.location.hostname === "localhost" ? "development" : "production";
  const apiUrl = config[environment].apiUrl;
  const [receiptData, setReceiptData] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationData, setConfirmationData] = useState({
    id: null,
    description: null,
  });
  const [searchText, setSearchText] = useState("");
  const [chartData, setChartData] = useState(null);
  const [totalSales, setTotalSales] = useState(0);
  const [averageSales, setAverageSales] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("3m"); // Default tab: 3 months

  // useEffect(() => {
  //     const firstDayOfMonth = format(startOfMonth(new Date()), "yyyy-MM-dd");
  //     const lastDayOfMonth = format(endOfMonth(new Date()), "yyyy-MM-dd");

  //     setStartDate(firstDayOfMonth);
  //     setEndDate(lastDayOfMonth);
  // }, []);

  useEffect(() => {
    setTabDateRange("3m"); // Set the default range to 3 months
  }, []);

  const setTabDateRange = (range) => {
    const today = new Date();
    let start, end;

    switch (range) {
      case "3m":
        start = subMonths(today, 3);
        end = today;
        break;
      case "6m":
        start = subMonths(today, 6);
        end = today;
        break;
      case "1y":
        start = subMonths(today, 12); // 1 year ago
        end = today;
        break;
      case "2y":
        start = subMonths(today, 24); // 2 years ago
        end = today;
        break;
      default:
        start = startOfMonth(today); // Fallback: Start of the current month
        end = endOfMonth(today); // End of the current month
    }

    setStartDate(format(start, "yyyy-MM-dd"));
    setEndDate(format(end, "yyyy-MM-dd"));
    setActiveTab(range);
  };

  useEffect(() => {
    const fetchTransactionData = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/all-summary-receipts/${transactionId}`,
        );
        const { receiptData } = response.data;
        setReceiptData(receiptData[0]);
      } catch (error) {
        console.error("Error retrieving receipt data:", error);
      }
    };

    fetchTransactionData();
  }, [transactionId, apiUrl]);

  useEffect(() => {
    fetchData();
  }, [startDate, endDate]);

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${apiUrl}/all-transaction-history`, {
        params: {
          startDate: startDate, // Example start date
          endDate: endDate, // Example end date
        },
      });
      setTransactionData(response.data);
      processChartData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching transaction history:", error);
      toast.error("Error fetching transaction history.");
      setLoading(false);
    }
  };

  const filteredData = transactionData.filter((item) => {
    // Check if the item matches the search text
    const matchesSearchText =
      !searchText ||
      Object.values(item).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(searchText.toLowerCase()),
      );

    return matchesSearchText;
    // return matchesSearchText && matchesStatusFilter && matchesEventTypeFilter;
  });

  const processChartData = (data) => {
    const monthlyData = {};
    let total = 0;

    // Aggregate data by month and description
    data.forEach((item) => {
      const month = new Date(item.recorded).toLocaleString("default", {
        month: "short",
        year: "numeric",
      });
      const description = item.description;

      if (!monthlyData[month]) monthlyData[month] = {};
      if (!monthlyData[month][description]) monthlyData[month][description] = 0;

      monthlyData[month][description] += item.amount;
      total += item.amount;
    });

    // Calculate metrics
    const months = Object.keys(monthlyData).length;
    setTotalSales(total);
    setAverageSales(months > 0 ? (total / months).toFixed(2) : 0);

    // Prepare chart data
    const monthsLabels = Object.keys(monthlyData);
    const descriptions = [...new Set(data.map((item) => item.description))];

    const datasets = descriptions.map((desc) => ({
      label: desc,
      data: monthsLabels.map((month) => monthlyData[month][desc] || 0),
      backgroundColor: getRandomColor(),
    }));

    setChartData({
      labels: monthsLabels,
      datasets: datasets,
    });
  };

  const calculateTotals = (data) => {
    const totals = {
      "Goats Sales": 0,
      "Eggs Sales": 0,
      "Broken Eggs": 0,
      "Pigs Sales": 0,
      "Poultry Sales": 0,
    };

    data.forEach((item) => {
      totals[item.description] += item.amount || 0;
    });

    return totals;
  };

  const totals = calculateTotals(filteredData);

  const getTopCategory = (totals) => {
    return Object.entries(totals).reduce((top, current) => {
      return current[1] > top[1] ? current : top;
    });
  };

  const [topCategory, topAmount] = getTopCategory(totals);

  const getTransactionCounts = (data) => {
    const monthlyCounts = {};

    data.forEach((item) => {
      const month = new Date(item.recorded || item.createdAt).toLocaleString(
        "default",
        {
          month: "short",
          year: "numeric",
        },
      );

      monthlyCounts[month] = (monthlyCounts[month] || 0) + 1;
    });

    return monthlyCounts;
  };

  const monthlyTransactionCounts = getTransactionCounts(filteredData);

  const calculateAverageTransaction = (data) => {
    const categoryTotals = {};
    const categoryCounts = {};

    data.forEach((item) => {
      categoryTotals[item.description] =
        (categoryTotals[item.description] || 0) + item.amount;
      categoryCounts[item.description] =
        (categoryCounts[item.description] || 0) + 1;
    });

    const averages = {};
    for (const category in categoryTotals) {
      averages[category] = categoryTotals[category] / categoryCounts[category];
    }

    return averages;
  };

  const averages = calculateAverageTransaction(filteredData);

  const getTotalTransactionsAndRevenue = (data) => {
    return {
      totalTransactions: data.length,
      totalRevenue: data.reduce((sum, item) => sum + item.amount, 0),
    };
  };

  const { totalTransactions, totalRevenue } =
    getTotalTransactionsAndRevenue(filteredData);

  const getRandomColor = () =>
    `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(
      Math.random() * 255,
    )}, ${Math.floor(Math.random() * 255)}, 0.6)`;

  return (
    <React.Fragment>
      <Col sm={12}>
        {success && (
          <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
            {success}
          </Alert>
        )}
        {error && (
          <Alert variant="danger" onClose={() => setError(null)} dismissible>
            {error}
          </Alert>
        )}
        <ToastContainer />
      </Col>
      <Card title="Filters" className="align-items-center">
        <div>
          {/* <span className="pr-3">Filters:</span> */}
          {["3m", "6m", "1y", "2y"].map((range) => (
            <Button
              key={range}
              onClick={() => setTabDateRange(range)}
              style={{
                marginRight: "5px",
                padding: "5px",
                backgroundColor: activeTab === range ? "#007bff" : "#f8f9fa",
                color: activeTab === range ? "white" : "black",
                border: activeTab === range ? "none" : "1px solid #ced4da",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              {range === "3m"
                ? "Last 3 Months"
                : range === "6m"
                ? "Last 6 Months"
                : range === "1y"
                ? "Last 1 Year"
                : "Last 2 Years"}
            </Button>
          ))}
          {/* </div>
                                            <div> */}
          <span className="px-3">Date Range:</span>
          <Form.Label>
            From:{" "}
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </Form.Label>
          <Form.Label style={{ marginLeft: "20px" }}>
            To:{" "}
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </Form.Label>

          <span className="px-3">Exports:</span>
        </div>
        <hr />
      </Card>
      <Row className="align-items-stretch mb-4">
        <Col xs={12} sm={12} lg={12} md={12}>
          <Row className="align-items-stretch mt-4">
            <Col sm={12} lg={6} md={6}>
              <Card>
                {/* <Card.Body> */}
                <div className="row">
                  <div className="col-md-6">
                    <h6>Overall Metrics</h6>
                    <p>
                      Total Transactions:{" "}
                      <span className="font-weight-bold">
                        {totalTransactions}
                      </span>
                    </p>
                  </div>
                  {/* <div>
                                        <h6>Monthly Transactions</h6>
                                        <ul>
                                            {Object.entries(monthlyTransactionCounts).map(([month, count]) => (
                                                <li key={month}>
                                                    {month}: <span className='font-weight-bold'>{count}</span>
                                                </li>
                                            ))}
                                        </ul>
                                     </div> */}
                  <div className="col-md-6">
                    <h6>Revenue</h6>
                    <p>
                      Total Revenue:{" "}
                      <span className="font-weight-bold">
                        KES{" "}
                        {totalRevenue.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </span>
                    </p>
                  </div>
                </div>

                {/* </Card.Body> */}
              </Card>
            </Col>
            <Col sm={12} lg={6} md={6}>
              <Card>
                {/* <Card.Body> */}
                <div className="row">
                  <div className="col-md-6">
                    <h6>Top Performing Category</h6>
                    <p>
                      {topCategory}:{" "}
                      <span className="font-weight-bold">
                        KES{" "}
                        {topAmount.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </span>
                    </p>
                  </div>
                  <div className="col-md-6">
                    <h6>Average Sales Per Month:</h6>
                    <p className="font-weight-bold">
                      KES{" "}
                      {averageSales.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </p>
                  </div>
                </div>
                {/* </Card.Body> */}
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xs={12} sm={12} lg={12} md={12}>
          <Card className="h-89" title="Sales Metrics" isOption>
            <div className="p-4">
              {loading ? (
                <p>Loading...</p>
              ) : error ? (
                <p style={{ color: "red" }}>{error}</p>
              ) : chartData ? (
                <Bar
                  style={{ maxHeight: "500px" }}
                  data={chartData}
                  options={{
                    responsive: true,
                    plugins: {
                      title: {
                        display: true,
                        text: "Sales Metrics by Month",
                      },
                    },
                  }}
                />
              ) : (
                <p>No data available</p>
              )}
            </div>
          </Card>
        </Col>

        <Col xs={12} sm={12} lg={12} md={12}>
          <Row className="align-items-stretch mb-2">
            {/* <Col sm={12} lg={12} md={6}> */}
            <Col sm={12} lg={6} md={6}>
              <Card title="Total Sales Summary">
                {/* <Card.Body> */}
                {/* <h6>Total Sales Summary</h6> */}
                <ul>
                  {Object.entries(totals).map(([category, amount]) => (
                    <li key={category}>
                      {category}:{" "}
                      <span className="font-weight-bold">
                        KES{" "}
                        {amount.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </span>
                    </li>
                  ))}
                </ul>
                {/* </Card.Body> */}
              </Card>
            </Col>
            <Col sm={12} lg={6} md={6}>
              <Card title="Average Transaction Value">
                {/* <Card.Body> */}
                {/* <h6>Average Transaction Value</h6> */}
                <ul>
                  {Object.entries(averages).map(([category, avg]) => (
                    <li key={category}>
                      {category}:{" "}
                      <span className="font-weight-bold">
                        KES{" "}
                        {avg.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </span>
                    </li>
                  ))}
                </ul>
                {/* </Card.Body> */}
              </Card>
            </Col>
            {/* </Col> */}
          </Row>
        </Col>
      </Row>
      {/* <ConfirmationModal show={showConfirmationModal} onHide={handleCancelConfirmation} onConfirm={handleConfirmation} /> */}
    </React.Fragment>
  );
};

export default TransactionHistory;
