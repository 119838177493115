import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { CSVLink } from "react-csv";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import {
  Row,
  Col,
  Form,
  Button,
  Table,
  Alert,
  Tabs,
  Tab,
  Modal,
  Pagination,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Font,
  PDFViewer,
  pdf,
} from "@react-pdf/renderer";
import Card from "../../components/Card/MainCard";
import config from "../../config";
import Receipt from "./GeneralReceipt1.js";
import DataTable from "react-data-table-component";
import { PDFContent } from "../../components/Receipts/index.js";

const TransactionHistory = () => {
  const [error, setError] = useState(null);
  const formRef = useRef(null);
  const [success, setSuccess] = useState(null);
  const [selectedTable, setSelectedTable] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [transactionData, setTransactionData] = useState([]);
  const [showReceipt, setShowReceipt] = useState(false);
  const [showReceiptModal, setShowReceiptModal] = useState(false);
  const [transactionId, setTransactionId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(10);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const environment =
    window.location.hostname === "localhost" ? "development" : "production";
  const apiUrl = config[environment].apiUrl;
  const [receiptData, setReceiptData] = useState(null);
  const [pdfBlob, setPdfBlob] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [totalMetrics, setTotalsMetrics] = useState(0);

  // useEffect(() => {
  //   const fetchTransactionData = async () => {
  //     try {
  //       const response = await axios.get(
  //         `${apiUrl}/all-summary-receipts/${transactionId}`,
  //       );
  //       const { receiptData } = response.data;
  //       setReceiptData(receiptData[0]);
  //     } catch (error) {
  //       console.error("Error retrieving receipt data:", error);
  //     }
  //   };

  //   fetchTransactionData();
  // }, [transactionId, apiUrl]);

  const handleTableChange = (selectedOption) => {
    setSelectedTable(selectedOption);
  };

  const handleModal = async (transactionId) => {
    console.log("View Button Clicked with Transaction ID:", transactionId);
    setSelectedTransaction(transactionId);
    setShowReceiptModal(true);
    setShowReceipt(true);
    setTransactionId(transactionId);
    try {
      const response = await axios.get(
        `${apiUrl}/summary-receipts/${selectedTable.value}/${transactionId}`,
      );
      setReceiptData(response.data.receiptData);
    } catch (error) {
      console.error("Error retrieving receipt data:", error);
    }
  };

  const generatePdf = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/summary-receipts/${selectedTable.value}/${transactionId}`,
      );
      setReceiptData(response.data.receiptData);

      // Convert the ReceiptPDF component into a Blob
      const blob = await pdf(
        <ReceiptPDF
          transactionId={transactionId}
          receiptData={response.data.receiptData}
        />,
      ).toBlob();
      setPdfBlob(blob);
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  const handleFetchData = async () => {
    // e.preventDefault();
    try {
      if (!selectedTable) {
        return; // If no table is selected, do not fetch data
      }

      const response = await axios.get(`${apiUrl}/api/transaction-history`, {
        params: {
          table: selectedTable.value,
          startDate: startDate,
          endDate: endDate,
        },
      });

      setTransactionData(response.data.data);
      setTotalsMetrics(response.data.totals);

      // console.log(response)
      if (response.data.data) {
        toast.success("Transaction history fetched successfully.");
        setSuccess("Transaction history fetched successfully.");
        setTimeout(() => {
          setSuccess(null);
        }, 10000);
      }
    } catch (error) {
      toast.error(error);
      console.error("Error fetching transaction history:", error);
    }
  };

  const tableOptions = [
    { value: "goats_sell", label: "Goat Sales" },
    { value: "eggs_sell", label: "Egg Sales" },
    { value: "pigs_sell", label: "Pig Sales" },
    { value: "poultry_sell", label: "Poultry Sales" },
    // { value: 'emergency_vaccination', label: 'Emergency Vaccination' },
    { value: "broken_eggs_sell", label: "Broken Eggs Sales" },
  ];

  const handleCloseReceiptModal = () => {
    setShowReceiptModal(false);
  };

  const handlePrint = () => {
    window.print();
  };

  const handleViewFile = (image) => {
    // Open the file in a new tab
    window.open(`${apiUrl}/uploads/${image}`, "_blank");
  };

  const csvLinkRef = useRef();

  const exportToCSV = () => {
    const csvData = [
      [
        "TransactionID",
        "Customer",
        "Amount",
        "Payment Mode",
        "Reference",
        "Date/Time",
        "Generated By",
        "Farm",
      ],
      ...transactionData.map((item) => [
        item.transactionId,
        item.cus_name,
        item.amount,
        item.pay_method,
        item.pay_reference,
        item.createdAt,
        item.servedBy,
        item.farm,
      ]),
    ];

    const csvContent = csvData.map((row) => row.join(",")).join("\n");
    const blob = new Blob(["\ufeff", csvContent], {
      type: "text/csv;charset=utf-8;",
    });

    const csvUrl = URL.createObjectURL(blob);
    csvLinkRef.current.href = csvUrl;
    csvLinkRef.current.download = "sfarms.csv";
    csvLinkRef.current.click();
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [
        [
          "TransactionID",
          "Customer",
          "Amount",
          "Payment Mode",
          "Reference",
          "Date/Time",
          "Generated By",
          "Farm",
        ],
      ],
      body: transactionData.map((user) => [
        user.transactionId,
        user.cus_name,
        user.amount.toLocaleString(),
        user.pay_method,
        user.pay_reference,
        new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "short",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }).format(new Date(user.createdAt)),
        user.servedBy,
        user.farm,
      ]),
    });
    const pdfBlob = doc.output("blob");
    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl);
  };

  const columns = [
    {
      name: "TimeSTamp",
      selector: (row) => row.createdAt,
      sortable: true,
      format: (row) =>
        row.createdAt ? new Date(row.createdAt).toLocaleString() : "null", // Format date
    },
    {
      name: "Transaction ID",
      selector: (row) => row.transactionId,
      sortable: true,
    },
    {
      name: "Customer",
      selector: (row) => row.cus_name,
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      format: (row) => (row.amount ? row.amount.toLocaleString() : "null"), // Format date
    },
    {
      name: "Payment Mode",
      selector: (row) => row.pay_method,
      sortable: true,
    },
    {
      name: "Generated By",
      selector: (row) => row.servedBy,
      sortable: true,
    },
    {
      name: "Receipt",
      cell: (row) => (
        <div>
          <Button onClick={() => handleModal(row.transactionId)}>
            <i className="feather icon-eye" />
          </Button>
          {row.image && (
            <Button onClick={() => handleViewFile(row.image)}>
              <i className="feather icon-download-cloud" />
              File
            </Button>
          )}
        </div>
      ),
      minWidth: "200px",
      maxWidth: "300px",
    },
  ];

  const filteredData = searchText
    ? transactionData?.filter((item) =>
        Object.values(item).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchText.toLowerCase()),
        ),
      )
    : transactionData;

  console.log(filteredData);

  return (
    <React.Fragment>
      <Row>
        <Col sm={12}>
          {success && (
            <Alert
              variant="success"
              onClose={() => setSuccess(null)}
              dismissible
            >
              {success}
            </Alert>
          )}
          {error && (
            <Alert variant="danger" onClose={() => setError(null)} dismissible>
              {error}
            </Alert>
          )}
          <ToastContainer />
        </Col>
      </Row>

      <Row>
        <Col>
          <Card title="Sales Summarry" isOption>
            {/* <Form onSubmit={handleFetchData} ref={formRef}> */}
            <Row className="d-flex align-items-center">
              <Col md={3} sm={12} lg={3}>
                <Form.Group>
                  <Form.Label>Select:</Form.Label>
                  <Select
                    value={selectedTable}
                    onChange={(selectedOption) =>
                      handleTableChange(selectedOption)
                    }
                    options={tableOptions}
                    placeholder="Select sales"
                  />
                </Form.Group>
              </Col>
              <Col md={3} sm={12} lg={3}>
                <Form.Group>
                  <Form.Label>From:</Form.Label>
                  <Form.Control
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col md={3} sm={12} lg={3}>
                <Form.Group>
                  <Form.Label>To:</Form.Label>
                  <Form.Control
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col md={3} sm={12} lg={3}>
                <Form.Group>
                  <Button onClick={handleFetchData}>Fetch Data</Button>
                </Form.Group>
              </Col>
            </Row>
            {/* </Form> */}
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card title="Total Volume" isOption>
            <div className="d-flex justify-content-between align-items-center">
             
              <div className="d-flex align-items-center">
                <img
                  src="/assets/profit.png"
                  alt="Cash Icon"
                  className=""
                  style={{ height: 30, width: 30 }}
                />
                <div className="d-block pl-4">
                  {/* <h6>Total Volume</h6> */}
                  <span className="f-8 font-weight-bold">
                    {totalMetrics?.grandTotalVolume?.toLocaleString()}
                  </span>
                </div>
              </div>
              
            </div>
          </Card>
        </Col>
        <Col>
          <Card title="Total Amount" isOption>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <img
                  src="/assets/cashless-payment.png"
                  alt="Cash Icon"
                  className=""
                  style={{ height: 30, width: 30 }}
                />
                <div className="d-block pl-4">
                  {/* <h6>Total Amount</h6> */}
                  <span className="f-8 font-weight-bold">
                    {totalMetrics?.grandTotalAmount?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                  <span>{}</span>
                </div>
              </div>
            </div>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card title="Receipts List" isOption>
            <Row>
              <Col sm={12}>
                <DataTable
                  // title="Transaction List"
                  columns={columns}
                  data={filteredData}
                  // keyField="uniqueKey"
                  // progressPending={loading}
                  pagination
                  highlightOnHover
                  striped
                  dense
                  bordered
                  paginationPerPage={10}
                  paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                  paginationComponentOptions={{
                    rowsPerPageText: "Rows per page:",
                  }}
                  paginationResetDefaultPage={true}
                  paginationTotalRows={transactionData.length}
                  // expandableRows
                  selectableRows
                  // onSelectedRowsChange={(selectedRows) => console.log(selectedRows)}
                  // onSelectedRowsChange={(state) =>
                  //   setSelectedRows(state.selectedRows)
                  // }
                  customStyles={{
                    headRow: {
                      style: {
                        backgroundColor: "#279e6c", // Green background
                        color: "#fff", // White text
                        textTransform: "uppercase", // Uppercase text
                        // fontSize: '10px', // Adjust font size for header row
                        minHeight: "1.5rem",
                        paddingTop: "8px",
                        paddingBottom: "8px",
                      },
                    },
                  }}
                />
              </Col>
            </Row>
          </Card>
          {showReceipt && (
            <Modal
              show={showReceiptModal}
              onHide={handleCloseReceiptModal}
              size="lg"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Receipt</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Receipt
                  transactionId={transactionId}
                  selectedTable={selectedTable}
                />
              </Modal.Body>
              <Modal.Footer>
                {receiptData && receiptData.transactionId && (
                  <PDFDownloadLink
                    className="btn btn-success mr-2"
                    document={
                      <PDFContent
                        id={receiptData?.id}
                        transactionData={receiptData}
                      />
                    }
                    fileName={`${receiptData?.transactionId}_${receiptData?.createdAt}.pdf`}
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        "Loading..."
                      ) : (
                        <span className="feather icon-printer"> Print</span>
                      )
                    }
                  </PDFDownloadLink>
                )}
                <Button variant="secondary" onClick={handleCloseReceiptModal}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};

const ReceiptPDF = ({ transactionId, receiptData }) => {
  return (
    <PDFViewer style={{ width: "100%", height: "100vh" }}>
      <Document>
        <Page style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.heading}>SFARMS SALES RECEIPT</Text>
            <Text style={styles.heading}>
              RECEIPT: #{receiptData.transactionId}
            </Text>
            <Text style={styles.heading}>
              DATE/TIME: {new Date(receiptData.createdAt).toLocaleString()}
            </Text>
            {receiptData.cus_name && (
              <View>
                <Text style={styles.label}>Customer:</Text>
                <Text style={styles.value}>{receiptData.cus_name}</Text>
              </View>
            )}
            {receiptData.birds && (
              <View>
                <Text style={styles.label}>No. of Birds:</Text>
                <Text style={styles.value}>{receiptData.birds}</Text>
              </View>
            )}
            {receiptData.tagNumber && (
              <View>
                <Text style={styles.label}>Tag No.:</Text>
                <Text style={styles.value}>{receiptData.tagNumber}</Text>
              </View>
            )}
            {receiptData.goats && (
              <View>
                <Text style={styles.label}>No. of Goats:</Text>
                <Text style={styles.value}>{receiptData.goats}</Text>
              </View>
            )}
            {receiptData.pigs && (
              <View>
                <Text style={styles.label}>No. of Pigs:</Text>
                <Text style={styles.value}>{receiptData.pigs}</Text>
              </View>
            )}
            {receiptData.trays && (
              <View>
                <Text style={styles.label}>No. of Trays:</Text>
                <Text style={styles.value}>{receiptData.trays}</Text>
              </View>
            )}
            {receiptData.eggs && (
              <View>
                <Text style={styles.label}>No. of Eggs:</Text>
                <Text style={styles.value}>{receiptData.eggs}</Text>
              </View>
            )}
            {receiptData.price && (
              <View>
                <Text style={styles.label}>Price:</Text>
                <Text style={styles.value}>
                  Ksh. {receiptData.price.toLocaleString()}
                </Text>
              </View>
            )}
            {receiptData.pay_method && (
              <View>
                <Text style={styles.label}>Payment Mode:</Text>
                <Text style={styles.value}>{receiptData.pay_method}</Text>
              </View>
            )}
            {receiptData.delivery && (
              <View>
                <Text style={styles.label}>Delivery Mode:</Text>
                <Text style={styles.value}>{receiptData.delivery}</Text>
              </View>
            )}
            {receiptData.del_guy && (
              <View>
                <Text style={styles.label}>Delivery Guy:</Text>
                <Text style={styles.value}>{receiptData.del_guy}</Text>
              </View>
            )}
            {receiptData.pay_reference && (
              <View>
                <Text style={styles.label}>Payment Reference:</Text>
                <Text style={styles.value}>{receiptData.pay_reference}</Text>
              </View>
            )}
            {receiptData.transactionId && (
              <View>
                <Text style={styles.label}>Transaction ID :</Text>
                <Text style={styles.value}>{receiptData.transactionId}</Text>
              </View>
            )}
            {receiptData.createdAt && (
              <View>
                <Text style={styles.label}>Date / Time :</Text>
                <Text style={styles.value}>
                  {new Date(receiptData.createdAt).toLocaleString()}
                </Text>
              </View>
            )}
            <View style={styles.footer}>
              <Text>Welcome Again</Text>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

// Register a custom font (optional)
Font.register({
  family: "Roboto",
  src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
});

const styles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
    padding: 20,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  heading: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 5,
  },
  label: {
    fontSize: 12,
    marginBottom: 3,
  },
  value: {
    fontSize: 12,
  },
  footer: {
    fontSize: 12,
    marginTop: 10,
    textAlign: "center",
  },
});
export default TransactionHistory;
