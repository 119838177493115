import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import config from "../../../config";
import { usePermissions } from "../../../permissions";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import Card from "../../../components/Card/MainCard";
import { Link } from "react-router-dom";

const TransactionsTable = ({ data }) => {
  const { createPermission, deletePermission, editPermission } =
    usePermissions();
  const [searchText, setSearchText] = useState("");

  const columns = [
    // {
    //     name: 'Id',
    //     selector: (row) => row?.id,
    //     sortable: true,
    //     wrap: true,
    //     maxWidth: '100px',
    //     minWidth: '100px'
    // },

    {
      name: "Invoice",
      selector: (row) => (
        <Link to={`/invoices/view/${row.id}`} className="text-color-blue">
          #{row.invoiceNumber}
        </Link>
      ),
      sortable: true,
      wrap: true,
      minWidth: "150px",
    },
    {
      name: "Status",
      selector: (row) => (
        <span className="text-capitalize">{row.paymentStatus}</span>
      ),
      sortable: true,
      wrap: true,
      minWidth: "150px",
    },

    {
      name: "Amount",
      selector: (row) =>
        row.totalAmount !== null
          ? row.totalAmount.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "-",
      sortable: true,
      wrap: true,
      minWidth: "180px",
    },
    {
      name: "Paid Amount",
      selector: (row) =>
        row.paidAmount !== null
          ? row.paidAmount.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "-",
      sortable: true,
      wrap: true,
      minWidth: "180px",
    },

    {
      name: "Invoice Date",
      selector: (row) => new Date(row.invoiceDate).toLocaleDateString(),
      sortable: true,
      wrap: true,
      minWidth: "180px",
    },

    {
      name: "Actions",
      cell: (row) => (
        <td>
          <Button
            size="sm"
            //   onClick={() => handleUpdate(row.id)}
          >
            Payment
          </Button>
          <Button
            variant="info"
            size="sm"
            //   onClick={() => handelDelete(row.id, row.employee)}
          >
            Send Email
          </Button>
        </td>
      ),
      wrap: true,
      minWidth: "250px",
    },
  ];

  const filteredData = searchText
    ? data.filter((item) =>
        Object.values(item).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchText.toLowerCase()),
        ),
      )
    : data;

  return (
    <div>
      <Card title="Invoices List" cardClassName="border-0 shadow-none" isOption>
        <Row>
          <Col sm={12}>
            {data && data.length > 0 && (
              <Row>
                <Form.Group
                  as={Col}
                  md={12}
                  sm={12}
                  lg={12}
                  controlId="formBasicName"
                >
                  <Form.Control
                    type="text"
                    placeholder="Search..."
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </Form.Group>
              </Row>
            )}
            <hr />
          </Col>

          <Col sm={12}>
            <DataTable
              // title="Transaction List"
              columns={columns}
              data={filteredData}
              keyField="uniqueKey"
              // progressPending={loading}
              pagination
              highlightOnHover
              striped
              dense
              bordered
              paginationPerPage={20}
              paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
              paginationComponentOptions={{
                rowsPerPageText: "Rows per page:",
              }}
              paginationResetDefaultPage={true}
              paginationTotalRows={data.length}
              // expandableRows
              selectableRows
              customStyles={{
                headRow: {
                  style: {
                    backgroundColor: "#279e6c", // Green background
                    color: "#fff", // White text
                    textTransform: "uppercase", // Uppercase text
                    // fontSize: '10px', // Adjust font size for header row
                    minHeight: "1.5rem",
                    paddingTop: "8px",
                    paddingBottom: "8px",
                  },
                },
              }}
            />
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default TransactionsTable;
