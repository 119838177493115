import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import Loader from './components/Loader/Loader';
import AdminLayout from './layouts/AdminLayout';

import GuestGuard from './components/Auth/GuestGuard';
import AuthGuard from './components/Auth/AuthGuard';
import RoleBasedGuard from './components/Auth/RoleBasedGuard';

import { BASE_URL } from './config/constant';

import DashDefault from './views/dashboard/DashDefault';
import BasicButton from './views/ui-elements/basic/BasicButton';
import BasicButtons from './views/ui-elements/basic/BasicButtons';
import BasicButton1 from './views/ui-elements/basic/BasicButton1.js';
import BasicButtons1 from './views/ui-elements/basic/BasicButtons1.js';
import BasicButton2 from './views/ui-elements/basic/BasicButton2.js';
import BasicButtons2 from './views/ui-elements/basic/BasicButtons2.js';
import BasicButton22 from './views/ui-elements/basic/BasicButton22.js';
import BasicButton002 from './views/ui-elements/basic/BasicButton002.js';
import BasicButton0012 from './views/ui-elements/basic/BasicButton0012.js';
import BasicButton00122 from './views/ui-elements/basic/BasicButton00122.js';
import BasicButton00102 from './views/ui-elements/basic/BasicButton00102.js';
import BasicButton0021 from './views/ui-elements/basic/BasicButton0021.js';
import BasicButton0022 from './views/ui-elements/basic/BasicButton0022.js';
import BasicButton102 from './views/ui-elements/basic/BasicButton102.js';
import BasicButton1021 from './views/ui-elements/basic/BasicButton1021.js';
import BasicButton0020 from './views/ui-elements/basic/BasicButton0020.js';
import BasicButton00201 from './views/ui-elements/basic/BasicButton00201.js';
import BasicButton00200 from './views/ui-elements/basic/BasicButton00200.js';
import BasicButton00211 from './views/ui-elements/basic/BasicButton00211.js';
import BasicButton002000 from './views/ui-elements/basic/BasicButton002000.js';
import BasicButton20 from './views/ui-elements/basic/BasicButton20.js';
import BasicButton21 from './views/ui-elements/basic/BasicButton21.js';
import BasicButton02 from './views/ui-elements/basic/BasicButton02.js';
import BasicButton03 from './views/ui-elements/basic/BasicButton03.js';
import BasicButton04 from './views/ui-elements/basic/BasicButton04.js';
import BasicButton0104 from './views/ui-elements/basic/BasicButton0104.js';
import BasicBadges from './views/ui-elements/basic/BasicBadges';
import BasicBreadcrumb1 from './views/ui-elements/basic/BasicBreadcrumb1';
import BasicBreadcrumb212 from './views/ui-elements/basic/BasicBreadcrumb212';
import BasicBreadcrumb2121 from './views/ui-elements/basic/BasicBreadcrumb2121.js';
import BasicBreadcrumb from './views/ui-elements/basic/BasicBreadcrumb';
import BasicBreadcrumbs from './views/ui-elements/basic/BasicBreadcrumbs.js';
import BasicBreadcrumbs01 from './views/ui-elements/basic/BasicBreadcrumbs01.js';
import BasicBreadcrumbs0101 from './views/ui-elements/basic/BasicBreadcrumbs0101.js';
import BasicBreadcrumbs0102 from './views/ui-elements/basic/BasicBreadcrumbs0102.js';
import BasicBreadcrumbs0103 from './views/ui-elements/basic/BasicBreadcrumbs0103.js';
import BasicBreadcrumbs02 from './views/ui-elements/basic/BasicBreadcrumbs02.js';
import BasicBreadcrumbs03 from './views/ui-elements/basic/BasicBreadcrumbs03.js';
import BasicBreadcrumbs04 from './views/ui-elements/basic/BasicBreadcrumbs04.js';
import BasicTypography1 from './views/ui-elements/basic/BasicTypography1.js';
import BasicTypography from './views/ui-elements/basic/BasicTypography';
import BasicTabsPills from './views/ui-elements/basic/BasicTabsPills';
import BasicTabsPills1 from './views/ui-elements/basic/BasicTabsPills1';
import BasicTabsPills01 from './views/ui-elements/basic/BasicTabsPills01';
import BasicTabsPills101 from './views/ui-elements/basic/BasicTabsPills101.js';
import BasicTabsPills2 from './views/ui-elements/basic/BasicTabsPills2';
import BasicTabsPills4 from './views/ui-elements/basic/BasicTabsPills4';
import BasicTabsPills40 from './views/ui-elements/basic/BasicTabsPills40';
import BasicTabsPills04 from './views/ui-elements/basic/BasicTabsPills04';
import BasicTabsPills400 from './views/ui-elements/basic/BasicTabsPills400';
import BasicTabsPills4000 from './views/ui-elements/basic/BasicTabsPills4000';
import BasicTabsPills040 from './views/ui-elements/basic/BasicTabsPills040';
import SamplePage11 from './views/extra/SamplePage11';
import SamplePage9 from './views/extra/SamplePage91';
import BasicCollapse from './views/ui-elements/basic/BasicCollapse';
import FormsElements from './views/forms/FormsElements';
import BootstrapTable from './views/tables/BootstrapTable';
import BasicCollapse2 from './views/ui-elements/basic/BasicCollapse2';
import BasicCollapse02 from './views/ui-elements/basic/BasicCollapse02';
import BasicCollapse12 from './views/ui-elements/basic/BasicCollapse12';
import FormsElements1 from './views/forms/FormsElements1';
import FormsElements10 from './views/forms/FormsElements10';
import FormsElements100 from './views/forms/FormsElements100';
import FormsElements1000 from './views/forms/FormsElements1000';
import BootstrapTable1 from './views/tables/BootstrapTable1';
import nvd3Chart from './views/charts/nvd3-chart';
import nvd3Chart01 from './views/charts/nvd3-chart01';
import nvd3Chart02 from './views/charts/nvd3-chart02';
import nvd3Chart03 from './views/charts/nvd3-chart03';
import nvd3Chart05 from './views/charts/nvd3-chart05';
import SamplePage from './views/extra/SamplePage';
import SamplePage1 from './views/extra/SamplePage1';
import nvd3Chart4 from './views/extra/nvd3-chart4';
import nvd3Chart100 from './views/extra/nvd3-chart100.js';
import nvd3Chart1001 from './views/extra/nvd3-chart1001.js';
import nvd3Chart04 from './views/extra/nvd3-chart04';
import Sample4 from './views/extra/Sample4';
import Sample10 from './views/extra/Sample10';
import Sample0 from './views/extra/Sample0';
import SamplePage01 from './views/extra/SamplePage01';
import SamplePage02 from './views/extra/SamplePage02';
import SamplePage020 from './views/extra/SamplePage020';
import SamplePage2 from './views/extra/SamplePage2';
import SamplePage3 from './views/extra/SamplePage3';
import SamplePage03 from './views/extra/SamplePage03';
import SamplePage010 from './views/extra/SamplePage010';
import BootstrapTable2 from './views/tables/BootstrapTable2';
import Sample02 from './views/extra/Sample02';
import Sample03 from './views/extra/Sample03';
import BootstrapTable02 from './views/tables/BootstrapTable02';
import SamplePage10 from './views/extra/SamplePage10';
import SamplePage12 from './views/extra/SamplePage12';
import SamplePage0012 from './views/extra/SamplePage0012';
import SamplePage120 from './views/extra/SamplePage120';
import SamplePage121 from './views/extra/SamplePage121';
import SamplePage00122 from './views/extra/SamplePage00122';
import SamplePage1201 from './views/extra/SamplePage1201';
import SamplePage1202 from './views/extra/SamplePage1202';
import SamplePage012 from './views/extra/SamplePage012';
import SamplePage013 from './views/extra/SamplePage013';
import SamplePage0130 from './views/extra/SamplePage0130';
import SamplePage014 from './views/extra/SamplePage014';
import SamplePage0014 from './views/extra/SamplePage0014';
import SamplePage00141 from './views/extra/SamplePage00141';
import SamplePage00014 from './views/extra/SamplePage00014';
import SamplePage015 from './views/extra/SamplePage015';
import SamplePage0150 from './views/extra/SamplePage0150';
import SamplePage0151 from './views/extra/SamplePage0151';
import SamplePage0152 from './views/extra/SamplePage0152';
import SamplePage0153 from './views/extra/SamplePage0153.js';
import BasicTabsPills3 from './views/ui-elements/basic/BasicTabsPills3';
import BasicTabsPills0003 from './views/ui-elements/basic/BasicTabsPills0003.js';
import BasicTabsPills03 from './views/ui-elements/basic/BasicTabsPills03';
import BasicTabsPills003 from './views/ui-elements/basic/BasicTabsPills003.js';
import BasicTabsPills0030 from './views/ui-elements/basic/BasicTabsPills0030.js';
import BasicTabsPills0031 from './views/ui-elements/basic/BasicTabsPills0031.js';
import SamplePage04 from './views/extra/SamplePage04';
import SamplePage4 from './views/extra/SamplePage4';
import SamplePage06 from './views/extra/SamplePage06';
import SamplePage6 from './views/extra/SamplePage6';
import SamplePage07 from './views/extra/SamplePage07';
import SamplePage7 from './views/extra/SamplePage7';
import SamplePage08 from './views/extra/SamplePage08';
import SamplePage8 from './views/extra/SamplePage8';
import Sample2 from './views/extra/Sample2';
import Sample3 from './views/extra/Sample3';
// import BasicTabsPills3 from './views/ui-elements/basic/BasicTabsPills3';
import BalanceSheet from './views/ui-elements/basic/BalanceSheet';
import Overview from './views/ui-elements/basic/Overview';
import Performance from './views/ui-elements/basic/Performance';
import CashFlow from './views/ui-elements/basic/CashFlow';
import ProfitLoss from './views/ui-elements/basic/ProfitLoss';
import BasicTabsPills0033 from './views/ui-elements/basic/BasicTabsPills0033.js';
import BasicTabsPills0035 from './views/ui-elements/basic/BasicTabsPills0035.js';
import BasicTabsPills0037 from './views/ui-elements/basic/BasicTabsPills0037.js';
import BasicTabsPills0039 from './views/ui-elements/basic/BasicTabsPills0039.js';
import BasicTabsPills0041 from './views/ui-elements/basic/BasicTabsPills0041.js';
import BasicTabsPills0043 from './views/ui-elements/basic/BasicTabsPills0043.js';

export const renderRoutes = (routes = [], userRole) => {
    return (
        <Suspense fallback={<Loader />}>
            <Switch>
                {routes.map((route, i) => {
                    const Guard = route.guard || Fragment;
                    const Layout = route.layout || Fragment;
                    const Component = route.component;

                    const isAuthPath = ['/auth/signin', '/auth/signin-2', 'reset-password/:token', '/auth/reset-password'].includes(
                        route.path
                    );

                    return (
                        <Route
                            key={i}
                            path={route.path}
                            exact={route.exact}
                            render={(props) => {
                                return (
                                    <Guard>
                                        {!isAuthPath ? (
                                            // <RoleBasedGuard path={route.path}>
                                            <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>
                                        ) : (
                                            // </RoleBasedGuard>
                                            <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>
                                        )}
                                    </Guard>
                                );
                            }}
                        />
                    );
                })}
            </Switch>
        </Suspense>
    );
};

const routes = [
    {
        exact: true,
        guard: GuestGuard,
        path: '/auth/signin',
        component: lazy(() => import('./views/auth/signin/SignIn1'))
    },
    {
        exact: true,
        guard: GuestGuard,
        path: '/reset-password/:token',
        component: lazy(() => import('./views/auth/signup/SignUp1'))
    },
    {
        exact: true,
        path: '/auth/signin-2',
        component: lazy(() => import('./views/auth/signin/SignIn2'))
    },
    {
        exact: true,
        path: '/auth/reset-password',
        component: lazy(() => import('./views/auth/signup/SignUp2'))
    },
    {
        path: '*',
        layout: AdminLayout,
        guard: AuthGuard,
        routes: [
            {
                exact: true,
                path: '/app/dashboard',
                component: DashDefault,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/users/employees',
                component: BasicButton,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/users/admins',
                guard: RoleBasedGuard,
                component: BasicButtons
            },
            {
                exact: true,
                path: '/users/roles',
                component: BasicBreadcrumbs01,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/users/roles/:roleId/edit',
                component: BasicBreadcrumbs0102
                // guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/users/roles/view/:roleId',
                component: BasicBreadcrumbs0101
                // guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/stakeholders/view-stakeholder/:id',
                component: BasicBreadcrumbs02
                // guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/users/roles/permissions',
                component: BasicBreadcrumbs0103
                // guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/users/details/:id',
                component: BasicButton1
                // guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/users/admins/admin-details/:id',
                component: BasicButtons1
                // guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/users/update-user/:id',
                component: BasicButton2
                // guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/users/admins/update-admin/:id',
                component: BasicButtons2
                // guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/users/edit-salary/:salaryId',
                component: BasicButton22
                // guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/accounts/view-loan/:id',
                component: BasicButton002
                // guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/accounts/view-transaction/:id',
                component: BasicButton00122
                // guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/invoices/view/:id',
                component: BasicButton0012
                // guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/general-expense/view/:id',
                component: BasicButton00102
                // guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/reports/animal-acquisition/view/:id/:description',
                component: BasicButton0021
                // guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/reports/stock-expense/view/:id/:description',
                component: BasicButton0022
                // guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/expenditure/view-expense/:id',
                component: BasicButton102
                // guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/users/payroll/salary_payment/:id',
                component: BasicButton1021
                // guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/poultry/view-batch/:batch',
                component: BasicButton0020
                // guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/poultry/view-eggs-update/:id',
                component: BasicButton00201
                // guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/pigs/view-tagNumber/:tagNumber',
                component: BasicButton00200
                // guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/weaners/view/:weanersGroup',
                component: BasicButton00211
                // guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/goats/tagNumber/:tagNumber',
                component: BasicButton002000
                // guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/users/update-password/:id',
                component: BasicButton20
                // guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/auth/password-change',
                component: BasicButton21
                // guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/customer/update-customer/:id',
                component: BasicButton02
                // guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/stakeholder/update-stakeholder/:id',
                component: BasicButton03
                // guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/vetenary/update-vetenary/:id',
                component: BasicButton04
                // guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/farm/update-farm/:id',
                component: BasicButton0104
                // guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/users/payroll',
                component: BasicBadges,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/calendar',
                component: BasicBreadcrumb1,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/invoices/overview',
                component: BasicBreadcrumb212,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/invoices/all-invoices',
                component: BasicBreadcrumb2121,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/communication/create',
                component: nvd3Chart100,
                guard: RoleBasedGuard
            },
            // {
            //     exact: true,
            //     path: '/communication/report',
            //     component: nvd3Chart1001,
            //     guard: RoleBasedGuard
            // },
            {
                exact: true,
                path: '/users/new-user',
                component: BasicBreadcrumb,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/admins/add-admin',
                component: BasicBreadcrumbs,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/poultry/poultry',
                component: BasicTypography1,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/poultry/new-poultry',
                component: BasicTypography,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/poultry/sell-poultry',
                component: BasicTabsPills,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/poultry/sell-eggs',
                component: BasicTabsPills1,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/poultry/sell-broken-eggs',
                component: BasicTabsPills01,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/poultry/report',
                component: BasicTabsPills101,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/poultry/vaccinate-poultry',
                component: BasicTabsPills2,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/pigs/consume-feeds',
                component: BasicTabsPills4,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/goats/consume-feeds',
                component: BasicTabsPills40,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/poultry/consume-feeds',
                component: BasicTabsPills04,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/pigs/feeds-consumption',
                component: BasicTabsPills400,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/goats/feeds-consumption',
                component: BasicTabsPills4000,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/poultry/feeds-consumption',
                component: BasicTabsPills040,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/poultry/poultry-updates',
                component: SamplePage11,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/poultry/eggs-updates',
                component: SamplePage9,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/pigs/add-pig',
                component: BasicCollapse,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/pigs/pig-update',
                component: FormsElements,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/pigs/sell-pigs',
                component: BootstrapTable,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/pigs/piglets-sired',
                component: BasicCollapse2,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/pigs/record-piglets-sired',
                component: BasicCollapse02,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/pigs/piglets-management',
                component: BasicCollapse12,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/pigs/vaccinate-pigs',
                component: FormsElements1,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/poultry/vaccination',
                component: FormsElements10,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/pigs/vaccination',
                component: FormsElements100,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/goats/vaccination',
                component: FormsElements1000,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/pigs/pigs',
                component: BootstrapTable1,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/goats/new-goats',
                component: nvd3Chart,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/goats/goats-update',
                component: SamplePage,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/goats/sell-goats',
                component: SamplePage1,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/goats/record-kids-sired',
                component: nvd3Chart4,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/goats/goats-sired',
                component: nvd3Chart04,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/goats/vaccinate-goats',
                component: Sample4,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/vaccination/emergency-vaccination',
                component: Sample10,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/vaccination/administer-emergency-vaccination',
                component: Sample0,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/goats/goats',
                component: SamplePage01,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/feeds',
                component: SamplePage02,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/feeds/consumed-feeds',
                component: SamplePage020
                // guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/feeds/new-feed',
                component: SamplePage2,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/feeds/feed-restock',
                component: SamplePage3,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/feeds/restock',
                component: SamplePage03,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/water',
                component: SamplePage04,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/water/water',
                component: SamplePage4,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/vaccines',
                component: SamplePage06,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/vaccine/add-vaccine',
                component: SamplePage6,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/vaccine/restock',
                component: SamplePage07,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/vaccine/vaccine-restock',
                component: SamplePage7,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/users/stock',
                component: SamplePage08,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/food',
                component: SamplePage08,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/food/add-food',
                component: SamplePage8,
                guard: RoleBasedGuard
            },
            // {
            //     exact: true,
            //     path: '/customer/add-customer',
            //     component: Sample2,
            //     guard: RoleBasedGuard
            // },
            // {
            //     exact: true,
            //     path: '/supplier/add-supplier',
            //     component: Sample3,
            //     guard: RoleBasedGuard
            // },
            // {
            //     exact: true,
            //     path: '/vetenary/add-vetenary',
            //     component: BootstrapTable2,
            //     guard: RoleBasedGuard
            // },
            // {
            //     exact: true,
            //     path: '/customers',
            //     component: Sample02,
            //     guard: RoleBasedGuard
            // },
            {
                exact: true,
                path: '/stakeholders',
                component: Sample03,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/accounts/all-transactions',
                component: BootstrapTable02,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/reports/production',
                component: SamplePage10,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/accounts',
                component: SamplePage12,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/accounts/balancesheet',
                component: SamplePage0012,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/accounts/new-account',
                component: SamplePage120,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/accounts/transfer',
                component: SamplePage121,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/accounts/loans',
                component: SamplePage00122,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/accounts/payments',
                component: SamplePage1201,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/accounts/statement',
                component: SamplePage1202,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/earnings',
                component: SamplePage012
                // guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/expenditure',
                component: SamplePage013
                // guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/mpesa/payment',
                component: SamplePage0130,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/transaction/sales-summary',
                component: SamplePage014,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/transaction/all-sales',
                component: SamplePage0014,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/transaction/overview-sales',
                component: SamplePage00141,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/accounts/new-transaction',
                component: SamplePage00014,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/reports/animal-history',
                component: SamplePage015,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/reports/animal-acquisition',
                component: SamplePage0150,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/reports/stock',
                component: SamplePage0151,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/reports/add-stock',
                component: SamplePage010
                // guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/reports/genaral-expenses',
                component: SamplePage0153,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/cash-flow',
                component: CashFlow,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/profit-loss',
                component: ProfitLoss,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/overview',
                component: Overview,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/reports/perfomance',
                component: Performance,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/balance-sheet',
                component: BalanceSheet,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/login-logs',
                component: BasicTabsPills3,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/logs',
                component: BasicTabsPills0003,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/farms',
                component: BasicTabsPills03,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/farms/add',
                component: BasicTabsPills003,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/farms/manage',
                component: BasicTabsPills0030,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/farms/assets',
                component: nvd3Chart03,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/assets/maintanance',
                component: nvd3Chart05,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/assets/add',
                component: nvd3Chart01,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/assets/maintain',
                component: nvd3Chart02,
                guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/income/:selectedFarm',
                component: BasicTabsPills0031
                // guard: RoleBasedGuard
            },

            {
                exact: true,
                path: '/expense/:selectedFarm',
                component: BasicTabsPills0033
                // guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/sales/:selectedFarm',
                component: BasicTabsPills0035
                // guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/acquisition/:selectedFarm',
                component: BasicTabsPills0037
                // guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/loans/:selectedFarm',
                component: BasicTabsPills0039
                // guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/payments/:selectedFarm',
                component: BasicTabsPills0041
                // guard: RoleBasedGuard
            },
            {
                exact: true,
                path: '/balancesheet/:selectedFarm',
                component: BasicTabsPills0043
                // guard: RoleBasedGuard
            },

            // {
            //     exact: true,
            //     path: '/expense/:selectedFarm',
            //     component: lazy(() => import('./views/ui-elements/basic/BasicTabsPills0033.js')),
            //     allowedRoles: ['Admin', 'User']
            // },
            // {
            //     exact: true,
            //     path: '/sales/:selectedFarm',
            //     component: lazy(() => import('./views/ui-elements/basic/BasicTabsPills0035.js')),
            //     allowedRoles: ['Admin', 'User']
            // },
            // {
            //     exact: true,
            //     path: '/acquisition/:selectedFarm',
            //     component: lazy(() => import('./views/ui-elements/basic/BasicTabsPills0037.js')),
            //     allowedRoles: ['Admin', 'User']
            // },
            // {
            //     exact: true,
            //     path: '/loans/:selectedFarm',
            //     component: lazy(() => import('./views/ui-elements/basic/BasicTabsPills0039.js')),
            //     allowedRoles: ['Admin', 'User']
            // },
            // {
            //     exact: true,
            //     path: '/payments/:selectedFarm',
            //     component: lazy(() => import('./views/ui-elements/basic/BasicTabsPills0041.js')),
            //     allowedRoles: ['Admin', 'User']
            // },
            // {
            //     exact: true,
            //     path: '/balancesheet/:selectedFarm',
            //     component: lazy(() => import('./views/ui-elements/basic/BasicTabsPills0043.js')),
            //     allowedRoles: ['Admin', 'User']
            // },

            {
                path: '*',
                exact: true,
                component: () => <Redirect to={BASE_URL} />
            }
        ]
    }
];

export default routes;
