import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { Row, Col, Form, Button, Alert, Table } from 'react-bootstrap';
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, PDFViewer, Font, Image } from '@react-pdf/renderer';
import config from './../../../config';
import axios from 'axios';
import Card from '../../../components/Card/MainCard';
import MontserratRegular from './../../../fonts/static/Montserrat-Regular.ttf';
import MontserratBold from './../../../fonts/static/Montserrat-Bold.ttf';

// Font.register({
//   family: 'Montserrat',
//   fonts: [
//     {
//       src: MontserratRegular,
//       fontWeight: 'normal',
//     },
//     {
//       src: MontserratBold,
//       fontWeight: 'bold',
//     },
//     // Register other font weights if needed
//   ],
// });

Font.register({
    family: 'Nunito',
    fonts: [
    { src: 'https://fonts.gstatic.com/s/nunito/v12/XRXV3I6Li01BKofINeaE.ttf' },
    { src: 'https://fonts.gstatic.com/s/nunito/v12/XRXW3I6Li01BKofA6sKUYevN.ttf', fontWeight: 700 },
    ],
})

const ViewInvoice = () => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [user, setUser] = useState({});
  const [loan, setLoan] = useState({});
  const [loanRepayments, setLoanRepayments] = useState([]);
  const { id, loanId } = useParams();
  const history = useHistory();
  const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
  const apiUrl = config[environment].apiUrl;
  const [pdfBlob, setPdfBlob] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${apiUrl}/transaction/${id}`);
        setUser(response.data[0]);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUser();
  }, [id]);

  const generatePdf = () => {
    const blob = new Blob([<PDFContent id={id} />], { type: 'application/pdf' });
    setPdfBlob(blob);
  };

  const getLabelColorClass = (status) => {
    switch (status) {
      case 'Fully Paid':
        return 'label-orange';
      case 'Partially Paid':
        return 'label-blue';
      case 'Ongoing':
        return 'label-till';
      case 'Expense':
        return 'label-brown';
      default:
        return '';
    }
  };

  

  return (
    <React.Fragment>

      <Row>
        <Col sm={12}>
        <Link to="/accounts/payments">
            <Button variant="primary" className="mb-3 mt-2">
              <span className='feather icon-arrow-left'></span>  Go Back
            </Button>
          </Link>
          <Card title="View Loan Payment" isOption>
              <Row>
                <Col sm={12} md={12} className='border-bottom'>
                    <div className="row d-flex align-items-center m-b-10">
                        <div className="col-12">
                            <div className="pdf-button-container">
                                <Button onClick={generatePdf} className="no-pdf">
                                  <i className="feather icon-printer f-40 text-c-white" />
                                </Button>
                                {pdfBlob && (
                                    <PDFDownloadLink
                                    document={<PDFContent id={id} user={user} />}
                                    fileName={`transaction_${id}.pdf`}
                                    >
                                    {({ blob, url, loading, error }) =>
                                        loading ? 'Loading...' : 'Print PDF'
                                    }
                                    </PDFDownloadLink>
                                )}
                                
                            </div>
                        </div>
                    </div>
                </Col>

                <Col sm={12} md={12} className='border-bottom'>
                  <div className="row d-flex align-items-center m-b-10">
                    <div className="col-4">
                      <img src={avatarImage} alt='Comapny Logo' style={{ width: '100px', height: 'auto', align: 'center' }} />
                    </div>
                    <div className="col-4">
                    </div>
                    <div className="col-4">
                      {/* <div>SFARMS.CO.KE</div> */}
                      <div>P.O BOX 800-222</div>
                      <div>Naivasha</div>
                      <div>Phone : 254-787-689-760</div>
                      <div>Email : info@sfarms.co.ke</div>
                    </div>
                  </div>
                </Col>
                
                <Col sm={12} md={12} className='border-bottom'>
                    <div className="row d-flex align-items-center m-t-20 m-b-20">
                        <div className="col-4">
                            <p className='m-t-10 m-b-10'>Person: {user.coInput}</p>
                            <p className='m-t-10 m-b-10'>Category: {user.category}</p>
                            <p className='m-t-10 m-b-10'>Amount: KES {user.amount}</p>
                            <p className='m-t-10 m-b-10'>Payment Method: {user.method}</p>
                        </div>

                        <div className="col-4">        
                        </div>                  
                        <div className="col-4">                          
                            <p className='m-t-10 m-b-10'>Account: {user.bankAccount}</p>      
                            <p className='m-t-10 m-b-10'>Loan Date : {new Date(user.date).toLocaleDateString()}</p>
                            <p className='m-t-10 m-b-10'>Transaction Id : {user.transactionId}</p>
                            <p className='m-t-10 m-b-10'>Type : {user.type}</p>
                        </div>
                    </div>
                </Col>

                <Col sm={12} md={12}>
                  <div className="row d-flex align-items-center m-t-20 m-b-20">
                        <div className="col-12">
                            <p className="text-uppercase m-1">Notes : {user.notes}</p>
                        </div>
                    </div>
                </Col>
              </Row>
          </Card>
        </Col>
      </Row>      
    </React.Fragment>
  );
};

const avatarImage = '/assets/farm-logo.jpg';

const PDFContent = ({ id, user }) => (
    <Document>
      <Page style={styles.page} size="A5" orientation="landscape">
        <View style={styles.section}>
        <View style={styles.row}> 
            <View style={styles.header} fixed>
              <View style={styles.rows}>
                <View style={styles.column}>
                  <Image source={avatarImage} style={styles.letterheadLogo} />
                </View>
              
                <View style={styles.column}>

                </View>
                <View style={styles.column}>
                  <Text>Address :</Text>
                  <Text>P.O BOX 800-222</Text>
                  <Text>Naivasha</Text>
                  <Text>Phone : 254-787-689-760</Text>
                  <Text>Email : info@sfarms.co.ke</Text>
                </View>
              </View>
            </View>
          </View>
          <Text style={styles.transactionId}>Transaction Details ID : #{id}</Text>
          
          <View style={styles.row}>
            <View style={styles.column}>
              <Text>Date : {new Date(user.createdAt).toLocaleString()}</Text>
            </View>
            <View style={styles.column}>
              <Text>Transaction ID : {user.transactionId}</Text>
            </View>
            <View style={styles.column}>
              <Text>{user.coInput}</Text>
              <Text>{user.method}</Text>
            </View>
          </View>
          
          <View style={styles.row}>
            <View style={styles.column}>
              <Text>Address :</Text>
              <Text>P.O BOX 800-222</Text>
              <Text>Naivasha</Text>
            </View>
            <View style={styles.column}>
              <Text>Phone : 254-787-689-760</Text>
              <Text>Email : info@sfarms.co.ke</Text>
              <Text>Categoty : {user.category}</Text>
            </View>
            <View style={styles.column}>
              <Text>{user.bankAccount}</Text>
              <Text>Amount : Ksh {user.amount.toLocaleString()}</Text>
              <Text>Type : {user.type}</Text>
            </View>
          </View>
          
          <Text style={styles.notes}>Notes: {user.notes}</Text>
        </View>
      </Page>
    </Document>
  );
  
  const styles = StyleSheet.create({
    page: {
      fontFamily: 'Nunito',
      fontSize: 10,
      flexDirection: 'row',
      backgroundColor: '#ffffff',
      width: '100%',
      marginTop: 40,
      height: '20px',
    },
    section: {
        // marginTop: 5,
        marginLeft: 50,
        marginRight: 50,
        padding: 10,
        flexGrow: 1,
    },
    header: {
      width: '100%',
      alignContent: 'center',
      // alignItems: 'center'
    },
    letterheadLogo: {
      width: 100,
      height: 100,
    },
    transactionId: {
      fontSize: 12,
      fontWeight: 'bold',
      marginBottom: 10,
      borderBottom: '0.5px solid grey',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 10,
    },
    rows: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems:'center',
      marginBottom: 10,
    },
    column: {
      width: '50%',
    },
    notes: {
      marginTop: 20,
      fontSize: 10,
    },
  });
  
export default ViewInvoice;
