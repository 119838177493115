import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Table, Row, Col } from "react-bootstrap";
import Select from "react-select";
import axios from "axios";
import config from "../../../config";
import { addDays, endOfMonth, format } from "date-fns";
import ConfirmationModal from "./ConfirmationModal";
import { toast } from "react-toastify";

const InvoiceFormModal = ({ show, onHide, transactionData, onSuccess }) => {
  const [recipientType, setRecipientType] = useState("");
  const [stakeholders, setStakeholders] = useState([]);
  const [stakes, setStakes] = useState([]);
  const [stakeholdersTrans, setStakeholdersTrans] = useState([]);
  const [selectedRecipient, setSelectedRecipient] = useState(null);
  const [terms, setTerms] = useState("Due on Receipt");
  const [customDuration, setCustomDuration] = useState(0);
  const [invoiceNumber, setInvoiceNumber] = useState("INV000001");
  const [invoiceDate, setInvoiceDate] = useState(
    new Date().toISOString().slice(0, 10),
  );
  const [dueDate, setDueDate] = useState("");
  const [recipientId, setRecipientId] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [notes, setNotes] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [description, setDescription] = useState("");
  const [itemDetailsDescription, setItemDetails] = useState("");
  const [data, setData] = useState({});
  const environment =
    window.location.hostname === "localhost" ? "development" : "production";
  const apiUrl = config[environment].apiUrl;
  const [balance, setBalance] = useState(0);
  const [stakeholderType, setStakeholderType] = useState("");
  const [items, setItems] = useState([
    {
      itemDetails: itemDetailsDescription || "",
      quantity: 1,
      rate: balance || 0,
    },
  ]);
  const currency = "KES";

  const resetForm = () => {
    setRecipientType("");
    setSelectedRecipient(null);
    setOrderNumber("");
    setInvoiceNumber("");
    setDescription("");
    // setInvoiceDate("");
    setTerms("Due on Receipt");
    // setCustomDuration(null);
    // setDueDate("");
    setNotes("");
    setItems([{ itemDetails: "", quantity: 1, rate: 0 }]);
  };

  const typeOptions = [
    { value: "customer", label: "Customer" },
    { value: "supplier", label: "Supplier" },
    { value: "veterinary", label: "Veterinary" },
  ];

  const termOptions = [
    { value: 15, label: "Net 15" },
    { value: 30, label: "Net 30" },
    { value: 45, label: "Net 45" },
    { value: 60, label: "Net 60" },
    { value: "Due on Receipt", label: "Due on Receipt" },
    { value: "endOfMonth", label: "Due end of the month" },
    { value: "endOfNextMonth", label: "Due end of next month" },
    { value: "Custom", label: "Custom" },
  ];

  const handleInput = (fieldName, value) => {
    setData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
    console.log(data);
  };

  useEffect(() => {
    fetchInvoiceNumber();
  }, []);

  const fetchInvoiceNumber = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/invoices/latest`); // API endpoint to fetch the latest invoice
      const latestInvoice = response.data?.invoiceNumber || "INV000000";

      // Generate the next invoice number
      const nextInvoiceNumber = generateNextInvoiceNumber(latestInvoice);
      setInvoiceNumber(nextInvoiceNumber);
    } catch (error) {
      console.error("Error fetching latest invoice number:", error);
    }
  };

  const generateNextInvoiceNumber = (currentNumber) => {
    const prefix = currentNumber.match(/[^\d]+/g)?.[0] || "INV"; // Extract the prefix
    const number = parseInt(currentNumber.replace(prefix, ""), 10) || 0; // Extract and parse the numeric part
    const nextNumber = (number + 1).toString().padStart(6, "0"); // Increment and pad with zeros
    return `${prefix}${nextNumber}`;
  };

  useEffect(() => {
    const fetchStakeholders = async () => {
      try {
        const res = await axios.get(`${apiUrl}/api/transactions`);
        const trans = res.data.transactions;
        const transOptions = trans.filter(
          (da) => da.stakeholder === selectedRecipient?.label,
        );
        const transactions = transOptions.map((stakeholder) => ({
          value: stakeholder.id,
          label: `${stakeholder.tableName} - ${stakeholder.transactionId}`,
        }));
        setStakeholdersTrans(transactions);
      } catch (error) {
        console.error("Error fetching stakeholders:", error);
      }
    };

    fetchStakeholders();
  }, [data.recipientType, transactionData]);

  useEffect(() => {
    const fetchStakeholders = async () => {
      try {
        const response = await axios.get(`${apiUrl}/stakeholders`);
        const res = response.data;
        setStakes(res);

        console.log(data.recipientType);

        const dataOptions = res.filter((d) => d.type === data.recipientType);

        const filteredOptions = dataOptions.map((stakeholder) => ({
          value: stakeholder.id,
          label: stakeholder.name,
          type: stakeholder.type,
        }));

        setStakeholders(filteredOptions);

        console.log(filteredOptions);

        console.log("fil", filteredOptions, "data", data);
      } catch (error) {
        console.error("Error fetching stakeholders:", error);
      }
    };

    fetchStakeholders();
  }, [data.recipientType]);

  useEffect(() => {
    if (
      transactionData &&
      transactionData.length > 0 &&
      stakes &&
      stakes.length > 0
    ) {
      const { stakeholder, tableName, transactionId, toPay, paidAmount } =
        transactionData[0];

      // Pre-fill values based on transaction data
      setSelectedRecipient({ label: stakeholder, value: stakeholder });
      setItemDetails(`${tableName} - Transaction ID: ${transactionId}`);
      setBalance(toPay - paidAmount);

      // Determine recipient type
      const stakeType = stakes.find(
        (d) => d.name.toLowerCase() === stakeholder.toLowerCase(),
      );
      if (stakeType) {
        const typeOption = typeOptions.find(
          (option) => option.value === stakeType.type,
        );
        setRecipientId(stakeType.id);
        setRecipientType(typeOption.value || null);
        console.log(stakeType, typeOption, stakes);
      } else {
        console.warn("Stakeholder type not found for:", stakeholder);
      }
    }
  }, [stakes, transactionData]);

  const rectype = typeOptions.find(
    (option) => option.value === recipientType,
  )?.value;

  const handleCancelConfirmation = () => {
    setShowConfirmationModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowConfirmationModal(true);
  };

  const handleConfirmation = async () => {
    setIsLoading(true);

    const invoiceData = {
      recipientType: rectype || data?.recipientType,
      selectedRecipient: selectedRecipient?.value || "",
      terms: terms === "Custom" ? `${customDuration} Days` : terms,
      invoiceNumber,
      invoiceDate,
      dueDate,
      items:
        transactionData && transactionData.length > 0
          ? [
              {
                itemDetails: itemDetailsDescription || "",
                quantity: 1,
                rate: balance || 0,
              },
            ]
          : items,
      notes,
      orderNumber: data?.orderNumber || "",
      description: data?.description || "",
      recipientId:
        transactionData && transactionData.length > 0
          ? recipientId
          : selectedRecipient?.value || "",
      total: items.reduce(
        (sum, item) => sum + item.quantity * (item?.rate || balance || 0),
        0,
      ),
      tableName:
        transactionData && transactionData.length > 0
          ? transactionData[0].tableName
          : "",
      tableNameId:
        transactionData && transactionData.length > 0
          ? transactionData[0].id
          : "",
    };

    try {
      const response = await axios.post(
        `${apiUrl}/api/process-invoice`,
        invoiceData,
      );
      // alert("Invoice successfully created!");
      if (response.data.message) {
        toast.success(response.data.message || "Invoice successfully created!");
      }
    } catch (error) {
      console.error("Error creating invoice:", error);
      toast.error("Failed to create invoice.");
    } finally {
      resetForm();
      onHide();
      setIsLoading(false);
      setShowConfirmationModal(false);
      fetchInvoiceNumber();
      onSuccess();
    }
  };

  useEffect(() => {
    calculateDueDate();
  }, [terms, invoiceDate, customDuration]);

  const calculateDueDate = () => {
    const invoiceDateObj = new Date(invoiceDate);

    if (terms === "Due on Receipt") {
      setDueDate(format(invoiceDateObj, "yyyy-MM-dd"));
    } else if (typeof terms === "number") {
      setDueDate(format(addDays(invoiceDateObj, terms), "yyyy-MM-dd"));
    } else if (terms === "Custom" && customDuration) {
      setDueDate(format(addDays(invoiceDateObj, customDuration), "yyyy-MM-dd"));
    } else if (terms === "endOfMonth") {
      setDueDate(format(endOfMonth(invoiceDateObj), "yyyy-MM-dd"));
    } else if (terms === "endOfNextMonth") {
      const nextMonth = addDays(invoiceDateObj, 30); // Approximation for next month
      setDueDate(format(endOfMonth(nextMonth), "yyyy-MM-dd"));
    } else {
      setDueDate(""); // Default empty for unsupported cases
    }
  };

  useEffect(() => {
    if (terms === "Due on Receipt") {
      setDueDate(invoiceDate);
    } else if (terms === "Custom") {
      const date = new Date(invoiceDate);
      date.setDate(date.getDate() + customDuration);
      setDueDate(date.toISOString().slice(0, 10));
    }
  }, [terms, customDuration, invoiceDate]);

  const calculateSubtotal = () => {
    return items
      .reduce(
        (sum, item) => sum + item.quantity * (item.rate || balance || 0),
        0,
      )
      .toLocaleString(undefined, {
        minimumFractionDigits: 2,
      });
  };

  const handleAddRow = () => {
    setItems([
      ...items,
      {
        itemDetails: itemDetailsDescription || "",
        quantity: 1,
        rate: balance || 0,
      },
    ]);
  };

  const handleRemoveRow = (index) => {
    setItems(items.filter((_, i) => i !== index));
  };

  const formattedRecordedDate =
    Array.isArray(transactionData) && transactionData.length > 0
      ? (() => {
          const recordedDate = new Date(transactionData[0]?.recorded);
          return !isNaN(recordedDate)
            ? recordedDate.toISOString().split("T")[0]
            : "";
        })()
      : "";

  // if (!formattedRecordedDate) {
  //   console.error('No valid recorded date found.');
  // }

  return (
    <>
      <Modal show={show} onHide={onHide} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>New Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {transactionData && transactionData.length > 0 ? (
            transactionData[0]?.invoiced === 0 ? (
              <Form onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Col>
                    <Form.Group>
                      <Form.Label>Recipient Type</Form.Label>
                      <Select
                        options={typeOptions}
                        // value={recipientType}
                        value={typeOptions.find(
                          (option) => option.value === rectype,
                        )}
                        onChange={(selectedOption) =>
                          setRecipientType(
                            handleInput("recipientType", selectedOption.value),
                          )
                        }
                        isSearchable
                        name="recipientType"
                        placeholder="--Select type--"
                        required
                        isDisabled={
                          transactionData && transactionData.length > 0
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Select Recipient</Form.Label>
                      <Select
                        options={stakeholders}
                        value={selectedRecipient}
                        onChange={setSelectedRecipient}
                        placeholder="Select recipient"
                        isDisabled={
                          transactionData && transactionData.length > 0
                        }
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col>
                    <Form.Group>
                      <Form.Label>Order Number</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter order number"
                        onChange={(e) =>
                          setOrderNumber(
                            handleInput("orderNumber", e.target.value),
                          )
                        }
                        name="orderNumber"
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Invoice#</Form.Label>
                      <Form.Control
                        type="text"
                        value={invoiceNumber}
                        readOnly
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col>
                    <Form.Group>
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={`let your ${
                          data?.recipientType || recipientType
                        } know what this invoice is for`}
                        onChange={(e) =>
                          setDescription(
                            handleInput("description", e.target.value),
                          )
                        }
                        name="description"
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mb-3">
                  {/* Invoice Date */}
                  <Col>
                    <Form.Group>
                      <Form.Label>Invoice Date</Form.Label>
                      <Form.Control
                        type="date"
                        value={invoiceDate}
                        min={formattedRecordedDate || undefined} // Use the formatted recorded date
                        onChange={(e) => setInvoiceDate(e.target.value)}
                        required
                      />
                    </Form.Group>
                  </Col>

                  {/* Terms */}
                  <Col>
                    <Form.Group>
                      <Form.Label>Terms</Form.Label>
                      <Select
                        options={termOptions}
                        value={termOptions.find(
                          (option) => option.value === terms,
                        )}
                        onChange={(selectedOption) =>
                          setTerms(selectedOption?.value ?? "Due on Receipt")
                        }
                        isSearchable
                        name="terms"
                        placeholder="--Select term--"
                        required
                      />
                      {terms === "Custom" && (
                        <Form.Control
                          type="number"
                          min="1"
                          placeholder="Enter duration in days"
                          value={customDuration ?? ""}
                          onChange={(e) =>
                            setCustomDuration(Number(e.target.value))
                          }
                        />
                      )}
                    </Form.Group>
                  </Col>

                  {/* Due Date */}
                  <Col>
                    <Form.Group>
                      <Form.Label>Due Date</Form.Label>
                      <Form.Control
                        type="date"
                        value={dueDate}
                        readOnly
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Table bordered responsive>
                  <thead>
                    <tr>
                      <th>Item Details</th>
                      <th>Quantity</th>
                      <th>Rate</th>
                      <th>Amount</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <Form.Control
                            type="text"
                            value={item.itemDetails || itemDetailsDescription}
                            onChange={(e) => {
                              const newItems = [...items];
                              newItems[index].itemDetails = e.target.value;
                              setItems(newItems);
                            }}
                            disabled={
                              transactionData && transactionData.length > 0
                            }
                            required
                          />
                        </td>
                        <td>
                          <Form.Control
                            type="number"
                            min="1"
                            value={item.quantity}
                            onChange={(e) => {
                              const newItems = [...items];
                              newItems[index].quantity = Number(e.target.value);
                              setItems(newItems);
                            }}
                            disabled={
                              transactionData && transactionData.length > 0
                            }
                            required
                          />
                        </td>
                        <td>
                          <Form.Control
                            type="number"
                            min="0"
                            value={item.rate || balance}
                            onChange={(e) => {
                              const newItems = [...items];
                              newItems[index].rate = Number(e.target.value);
                              setItems(newItems);
                            }}
                            disabled={
                              transactionData && transactionData.length > 0
                            }
                            required
                          />
                        </td>
                        <td>
                          {(item.quantity * (item.rate || balance)).toFixed(2)}
                        </td>
                        <td>
                          {transactionData && transactionData.length > 0 ? (
                            "-"
                          ) : (
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleRemoveRow(index)}
                            >
                              'Remove'
                            </Button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                {transactionData && transactionData.length > 0 ? (
                  "-"
                ) : (
                  <Button variant="secondary" onClick={handleAddRow}>
                    + Add Item
                  </Button>
                )}

                <Row>
                  <Col>
                    <Form.Group className="mt-3">
                      <Form.Label>
                        <span className="text-capitalize">
                          {data?.recipientType || recipientType}
                        </span>{" "}
                        Notes
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={2}
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <div className="text-end mt-3">
                      <Table responsive hover striped>
                        <tbody>
                          <tr>
                            <td>Subtotal:</td>
                            <td>{calculateSubtotal()}</td>
                          </tr>
                          <tr>
                            <td>Total:</td>
                            <td>
                              {currency} {calculateSubtotal()}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>

                <Button variant="primary" type="submit">
                  {isLoading ? "Submitting..." : "Save Invoice"}
                </Button>
              </Form>
            ) : (
              <span>
                Invoice for {transactionData[0]?.stakeholder}-
                {transactionData[0]?.transactionId} already invoiced
              </span>
            )
          ) : (
            <Form onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col>
                  <Form.Group>
                    <Form.Label>Recipient Type</Form.Label>
                    <Select
                      options={typeOptions}
                      // value={recipientType}
                      value={typeOptions.find(
                        (option) => option.value === rectype,
                      )}
                      onChange={(selectedOption) =>
                        setRecipientType(
                          handleInput("recipientType", selectedOption.value),
                        )
                      }
                      isSearchable
                      name="recipientType"
                      placeholder="--Select type--"
                      required
                      isDisabled={transactionData && transactionData.length > 0}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Select Recipient</Form.Label>
                    <Select
                      options={stakeholders}
                      value={selectedRecipient}
                      onChange={setSelectedRecipient}
                      placeholder="Select recipient"
                      isDisabled={transactionData && transactionData.length > 0}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  <Form.Group>
                    <Form.Label>Order Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter order number"
                      onChange={(e) =>
                        setOrderNumber(
                          handleInput("orderNumber", e.target.value),
                        )
                      }
                      name="orderNumber"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Invoice#</Form.Label>
                    <Form.Control
                      type="text"
                      value={invoiceNumber}
                      readOnly
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  <Form.Group>
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={`let your ${
                        data?.recipientType || recipientType
                      } know what this invoice is for`}
                      onChange={(e) =>
                        setDescription(
                          handleInput("description", e.target.value),
                        )
                      }
                      name="description"
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                {/* Invoice Date */}
                <Col>
                  <Form.Group>
                    <Form.Label>Invoice Date</Form.Label>
                    <Form.Control
                      type="date"
                      value={invoiceDate}
                      onChange={(e) => setInvoiceDate(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Col>

                {/* Terms */}
                <Col>
                  <Form.Group>
                    <Form.Label>Terms</Form.Label>
                    <Select
                      options={termOptions}
                      value={termOptions.find(
                        (option) => option.value === terms,
                      )}
                      onChange={(selectedOption) =>
                        setTerms(selectedOption?.value ?? "Due on Receipt")
                      }
                      isSearchable
                      name="terms"
                      placeholder="--Select term--"
                      required
                    />
                    {terms === "Custom" && (
                      <Form.Control
                        type="number"
                        min="1"
                        placeholder="Enter duration in days"
                        value={customDuration ?? ""}
                        onChange={(e) =>
                          setCustomDuration(Number(e.target.value))
                        }
                      />
                    )}
                  </Form.Group>
                </Col>

                {/* Due Date */}
                <Col>
                  <Form.Group>
                    <Form.Label>Due Date</Form.Label>
                    <Form.Control
                      type="date"
                      value={dueDate}
                      readOnly
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Table bordered responsive>
                <thead>
                  <tr>
                    <th>Item Details</th>
                    <th>Quantity</th>
                    <th>Rate</th>
                    <th>Amount</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <Form.Control
                          type="text"
                          value={item.itemDetails || itemDetailsDescription}
                          onChange={(e) => {
                            const newItems = [...items];
                            newItems[index].itemDetails = e.target.value;
                            setItems(newItems);
                          }}
                          disabled={
                            transactionData && transactionData.length > 0
                          }
                          required
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="number"
                          min="1"
                          value={item.quantity}
                          onChange={(e) => {
                            const newItems = [...items];
                            newItems[index].quantity = Number(e.target.value);
                            setItems(newItems);
                          }}
                          disabled={
                            transactionData && transactionData.length > 0
                          }
                          required
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="number"
                          min="0"
                          value={item.rate || balance}
                          onChange={(e) => {
                            const newItems = [...items];
                            newItems[index].rate = Number(e.target.value);
                            setItems(newItems);
                          }}
                          disabled={
                            transactionData && transactionData.length > 0
                          }
                          required
                        />
                      </td>
                      <td>
                        {(item.quantity * (item.rate || balance)).toFixed(2)}
                      </td>
                      <td>
                        {transactionData && transactionData.length > 0 ? (
                          "-"
                        ) : (
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => handleRemoveRow(index)}
                          >
                            'Remove'
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              {transactionData && transactionData.length > 0 ? (
                "-"
              ) : (
                <Button variant="secondary" onClick={handleAddRow}>
                  + Add Item
                </Button>
              )}

              <Row>
                <Col>
                  <Form.Group className="mt-3">
                    <Form.Label>
                      <span className="text-capitalize">
                        {data?.recipientType || recipientType}
                      </span>{" "}
                      Notes
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <div className="text-end mt-3">
                    <Table responsive hover striped>
                      <tbody>
                        <tr>
                          <td>Subtotal:</td>
                          <td>{calculateSubtotal()}</td>
                        </tr>
                        <tr>
                          <td>Total:</td>
                          <td>
                            {currency} {calculateSubtotal()}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>

              <Button variant="primary" type="submit">
                {isLoading ? "Submitting..." : "Save Invoice"}
              </Button>
            </Form>
          )}
        </Modal.Body>
      </Modal>

      <ConfirmationModal
        show={showConfirmationModal}
        onHide={handleCancelConfirmation}
        onConfirm={handleConfirmation}
      />
    </>
  );
};

export default InvoiceFormModal;
