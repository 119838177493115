import React, { useEffect, useState } from 'react';
import { Row, Col, Tabs, Tab, Button, Table, Accordion } from 'react-bootstrap';
import Card from '../../../components/Card/MainCard';
import { useParams } from 'react-router-dom';
import config from '../../../config';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import Transact from './TransactionsList';

const SamplePage = () => {
    const { id } = useParams();
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [searchText, setSearchText] = useState('');
    const [data, setData] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [details, setDetails] = useState([]);
    const [transactions, setTransactions] = useState([]);

    useEffect(() => {
        const fetchRoleAndPermissions = async () => {
            try {
                const roleResponse = await axios.get(`${apiUrl}/api/stakeholders/${id}`);
                const detailsResponse = await axios.get(`${apiUrl}/api/stakeholders-details/${id}`);
                const transactionsResponse = await axios.get(`${apiUrl}/api/transactions`);
                const response = await axios.get(`${apiUrl}/api/invoices`);

                const stakeholder = detailsResponse.data?.stakeholder;

                // Ensure transactionsResponse.data is an array before filtering
                const filteredTransactions = Array.isArray(transactionsResponse.data.transactions)
                    ? transactionsResponse.data.transactions.filter((transaction) => transaction.stakeholder === stakeholder)
                    : [];

                    const filteredInvoices = Array.isArray(response.data)
                    ? response.data.filter((invoice) => String(invoice.recipientId) === String(id))
                    : [];

                setData(roleResponse.data[0]);
                setDetails(detailsResponse.data);
                setInvoices(filteredInvoices);
                setTransactions(filteredTransactions);
            } catch (error) {
                console.error('Error fetching role, details, or transactions:', error);
            }
        };

        fetchRoleAndPermissions();
    }, [id]);

    const incomeExpenseChartData = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
                label: 'Income',
                data: details?.barGraph?.income?.map((item) => parseFloat(item.income || 0)),
                backgroundColor: 'rgba(40, 167, 69, 0.6)', // Green
                borderColor: 'rgba(40, 167, 69, 1)',
                borderWidth: 1
            }
        ]
    };

    const expenseChartData = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
                label: 'Expenses',
                data: details?.barGraph?.expenses?.map((item) => parseFloat(item.expense || 0)),
                backgroundColor: 'rgba(220, 53, 69, 0.6)', // Red
                borderColor: 'rgba(220, 53, 69, 1)',
                borderWidth: 1
            }
        ]
    };

    return (
        <React.Fragment>
            <Link to="/stakeholders">
                {' '}
                <Button className="mb-4">
                    {' '}
                    <span className="feather icon-arrow-left"></span> Back{' '}
                </Button>
            </Link>

            <Tabs defaultActiveKey="overview" className="mb-3">
                {/* Overview Tab */}
                <Tab eventKey="overview" title="Overview" className="mt-4">
                    <Row>
                        {/* Left Column */}
                        <Col sm={12} md={4} lg={4}>
                            <Card title="Profile" isOption>
                                <div className="row d-flex">
                                    <div className="col-4">
                                        <img src="/assets/download.jpg" alt="hi" style={{ minWidth: '40px', maxWidth: '100px' }} />
                                    </div>
                                    <div className="col-8 text-left d-block">
                                        <div>{data?.name}</div>
                                        <div>📞 {data?.phone}</div>
                                        <div>📩 {data?.email} </div>
                                        <Button variant="link">Invite to Portal</Button> | <Button variant="link">Delete</Button>
                                    </div>{' '}
                                </div>
                                <hr />
                                <div>
                                    <p>
                                        <strong>Billing Address:</strong> Add new address
                                    </p>
                                    <p>
                                        <strong>Shipping Address:</strong> Add new address
                                    </p>
                                </div>
                                <hr />
                                <div className="row">
                                    <p className="col-6">Type</p>
                                    <strong className="text-capitalize col-6 text-left"> {data?.type}</strong>
                                    <p className="col-6">Location</p>
                                    <strong className="text-capitalize col-6 text-left">{data?.location}</strong>
                                    {data?.speciality && (
                                        <>
                                            <p className="col-6">Speciality</p>
                                            <strong className="text-capitalize col-6 text-left">{data?.speciality}</strong>
                                        </>
                                    )}
                                    <p className="col-6">Portal Language</p>
                                    <strong className="text-capitalize col-6 text-left">English</strong>
                                </div>
                                <hr />
                                <div className="row">
                                    <p className="col-6">Stakeholders ID</p>
                                    <strong className="text-capitalize col-6 text-left"> {data?.id}</strong>
                                    <p className="col-6">Created On</p>
                                    <strong className="text-capitalize col-6 text-left">
                                        {new Date(data?.created_at)?.toLocaleDateString()}
                                    </strong>
                                    <p className="col-6">Crreated By</p>
                                    <strong className="text-capitalize col-6 text-left">{data?.userName}</strong>
                                </div>
                            </Card>
                        </Col>

                        {/* Right Column */}
                        <Col sm={12} md={8} lg={8}>
                            {(details?.totalReceivables || details?.barGraph?.income) && (
                                <Card title="Receivables" isOption>
                                    <div>
                                        <p>
                                            <strong>Due On Receipt</strong>
                                        </p>
                                        <Table bordered>
                                            <thead>
                                                <tr>
                                                    <th>Currency</th>
                                                    <th>Outstanding Receivables</th>
                                                    <th>Unused Credits</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>KES - Kenyan Shilling</td>
                                                    <td>
                                                        KES{' '}
                                                        {details?.totalReceivables?.toLocaleString(undefined, {
                                                            minimumFractionDigits: 2
                                                        })}
                                                    </td>
                                                    <td>KES 0.00</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                    <div>
                                        {/* Example chart placeholder */}
                                        {/* <div style={{ background: '#f5f5f5', maxHeight: '200px', width: '100%' }}> */}
                                            <Bar data={incomeExpenseChartData} style={{ maxHeight: '250px', width: '100%' }}/>
                                        {/* </div> */}
                                        <h6 className="mt-3">
                                            Total Income: KES{' '}
                                            {details?.totalIncome?.toLocaleString(undefined, {
                                                minimumFractionDigits: 2
                                            })}
                                        </h6>
                                    </div>
                                </Card>
                            )}

                            {(details?.totalPayables || details?.barGraph?.expenses) && (
                                <Card title="Payables" isOption>
                                    <div>
                                        <p>
                                            <strong>Due On Receipt</strong>
                                        </p>
                                        <Table bordered>
                                            <thead>
                                                <tr>
                                                    <th>Currency</th>
                                                    <th>Outstanding Payables</th>
                                                    <th>Unused Credits</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>KES - Kenyan Shilling</td>
                                                    <td>
                                                        KES{' '}
                                                        {details?.totalPayables?.toLocaleString(undefined, {
                                                            minimumFractionDigits: 2
                                                        })}
                                                    </td>
                                                    <td>KES 0.00</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                    <div>
                                        {/* Example chart placeholder */}
                                        {/* <div style={{ background: '#f5f5f5', maxHeight: '200px', width: '100%' }}> */}
                                            <Bar data={expenseChartData} style={{ maxHeight: '250px', width: '100%' }} />
                                        {/* </div> */}
                                        <h6 className="mt-3">
                                            Total Expense: KES{' '}
                                            {details?.totalExpenses?.toLocaleString(undefined, {
                                                minimumFractionDigits: 2
                                            })}
                                        </h6>
                                    </div>
                                </Card>
                            )}
                            
                            {/* <Card title="Activity Timeline" isOption>
                                <div>
                                    <p>
                                        <strong>28/10/2024, 4:43 PM</strong>: Payment of amount KES 22.00 received and applied for INV000001
                                    </p>
                                    <p>
                                        <strong>28/10/2024, 4:42 PM</strong>: Invoice INV000001 marked as sent
                                    </p>
                                    <p>
                                        <strong>28/10/2024, 4:39 PM</strong>: Invoice INV000001 of amount KES 222.00 created
                                    </p>
                                </div>
                            </Card> */}
                        </Col>
                    </Row>
                </Tab>

                {/* Comments Tab */}
                {/* <Tab eventKey="comments" title="Comments" className="mt-4">
                    <p>Comments section will go here.</p>
                </Tab> */}

                {/* Transactions Tab */}
                <Tab eventKey="transactions" title="Transactions" className="mt-4">
                    {/* <p>Transactions section will go here.</p> */}
                    <Transact data={transactions} invoices={invoices} />
                </Tab>

                {/* Mails Tab */}
                {/* <Tab eventKey="mails" title="Mails" className="mt-4">
                    <p>Mails section will go here.</p>
                </Tab> */}

                {/* Statements Tab */}
                <Tab eventKey="statements" title="Statements" className="mt-4">
                    <p>Statements section will go here.</p>
                </Tab>
            </Tabs>
        </React.Fragment>
    );
};

export default SamplePage;
