import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Table, Row, Col } from "react-bootstrap";
import Select from "react-select";
import axios from "axios";
import config from "../../../config";
import { addDays, endOfMonth, format } from "date-fns";
import ConfirmationModal from "./ConfirmationModal";
import { toast } from "react-toastify";
import { te } from "date-fns/locale";
import { useSelector } from "react-redux";

const InvoicePay = ({ show, onHide, invoiceData, onSuccess }) => {
  const account = useSelector((state) => state.account);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const environment =
    window.location.hostname === "localhost" ? "development" : "production";
  const apiUrl = config[environment].apiUrl;
  const [paymentDate, setPaymentDate] = useState("");
  const [amountPaid, setAmountPaid] = useState(0);
  const [paymentPurpose, setPaymentPurpose] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("cash");
  const [transactionId, setTransactionId] = useState("");
  const [paidBy, setPaidBy] = useState(account?.user?.id);

  const paymentMethodOptions = [
    { value: "cash", label: "Cash" },
    { value: "credit_card", label: "Credit Card" },
    { value: "bank_transfer", label: "Bank Transfer" },
    { value: "mobile_money", label: "Mobile Money" },
    { value: "paypal", label: "PayPal" },
    { value: "other", label: "Other" },
  ];

  // console.log(paymentMethod);

  const handleCancelConfirmation = () => {
    setShowConfirmationModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowConfirmationModal(true);
  };

  const handleConfirmation = async () => {
    setIsLoading(true);

    const formData = {
      ...invoiceData,
      paymentDate: paymentDate,
      amountPaid: amountPaid,
      paymentMethod: paymentMethod?.value,
      transactionId: transactionId,
      paidBy: paidBy,
      paymentPurpose: `Payment for invoice ${invoiceData?.invoiceNumber}`,
    };

    try {
      const response = await axios.post(
        `${apiUrl}/api/update-payment`,
        formData,
      );
      // alert("Invoice successfully created!");
      if (response.data.message) {
        toast.success(response.data.message || "Invoice successfully paid!");
      }
    } catch (error) {
      console.error("Error paying invoice:", error);
      toast.error("Failed to pay invoice.");
    } finally {
      onHide();
      setIsLoading(false);
      setShowConfirmationModal(false);
      onSuccess();
      setPaymentDate("");
      setAmountPaid("");
      setPaymentMethod("");
      setTransactionId("");
    }
  };

  useEffect(() => {
    if (invoiceData?.totalAmount !== undefined) {
      const balance = invoiceData?.totalAmount - invoiceData?.paidAmount;
      setAmountPaid(balance); // Update only when invoiceData changes
    }
  }, [invoiceData?.totalAmount]); // Only run when invoiceData changes

  return (
    <>
      <Modal show={show} onHide={onHide} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Invoice Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {invoiceData &&
          invoiceData?.paymentStatus !== "paid" &&
          invoiceData?.paidAmount < invoiceData?.totalAmount ? (
            <Form onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col>
                  <Form.Group>
                    <Form.Label>Invoice Number</Form.Label>
                    <Form.Control
                      type="text"
                      value={invoiceData?.invoiceNumber}
                      readOnly
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Form.Group>
                    <Form.Label>Payment Method</Form.Label>
                    <Select
                      options={paymentMethodOptions}
                      value={paymentMethod}
                      onChange={(selectedOption) =>
                        setPaymentMethod(selectedOption)
                      }
                      isSearchable
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Payment Date</Form.Label>
                    <Form.Control
                      type="date"
                      value={paymentDate}
                      onChange={(e) => setPaymentDate(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              {/* Transaction ID */}
              {paymentMethod?.value !== "cash" && (
                <Row className="mb-3">
                  <Col>
                    <Form.Group>
                      <Form.Label>Transaction ID</Form.Label>
                      <Form.Control
                        as={"textarea"}
                        type="textarea"
                        value={transactionId}
                        onChange={(e) => setTransactionId(e.target.value)}
                        placeholder="Enter Transaction ID"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              )}

              <Row className="mb-3">
                <Col>
                  <Form.Group>
                    <Form.Label>Amount Paid</Form.Label>
                    <Form.Control
                      type="number"
                      value={amountPaid}
                      onChange={(e) => setAmountPaid(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Button variant="primary" type="submit" disabled={isLoading}>
                {isLoading ? "Submitting..." : "Pay"}
              </Button>
            </Form>
          ) : (
            <p>Fully paid</p>
          )}
        </Modal.Body>
      </Modal>

      <ConfirmationModal
        show={showConfirmationModal}
        onHide={handleCancelConfirmation}
        onConfirm={handleConfirmation}
      />
    </>
  );
};

export default InvoicePay;
