import React, { useEffect, useState } from "react";
// import jsPDF from "jspdf";
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, PDFViewer, Font, Image } from '@react-pdf/renderer';

import { Button, Col, Row } from "react-bootstrap";
import Card from "../Card/MainCard";
import DataTable from "react-data-table-component";
import config from "../../config";
import axios from 'axios';
import { format, set } from "date-fns";
import { Link } from "react-router-dom";

    Font.register({
        family: 'Nunito',
        fonts: [
        { src: 'https://fonts.gstatic.com/s/nunito/v12/XRXV3I6Li01BKofINeaE.ttf' },
        { src: 'https://fonts.gstatic.com/s/nunito/v12/XRXW3I6Li01BKofA6sKUYevN.ttf', fontWeight: 700 },
        ],
    })

    const avatarImage = '/assets/farm-logo.jpg';
    const downloadImage = '/assets/download.png';

    const Invoice = ({ invoiceData }) => {
        const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
        const apiUrl = config[environment].apiUrl;
        const [stakeholders, setStakeholders] = useState([]);
        const [balance, setTotalBalance] = useState(0);

        const currency = "KES";

        useEffect(() => {
            const fetchUser = async () => {
            try {
                const response = await axios.get(`${apiUrl}/stakeholder/${invoiceData?.recipientId}`);
                const data = response.data[0];
                setStakeholders(data);
            } catch (error) {
                console.log(error);
            }
            };
            fetchUser();
        }, [invoiceData?.recipientId]);
    
        const calculateSubTotal = () => {
            return invoiceData?.lineItems?.reduce((total, item) => total + item?.total, 0);
        };

        const calculateTotal = () => {
            const subTotal = calculateSubTotal();
            const tax = invoiceData?.paidAmount;       
            return subTotal - tax;
        };

        useEffect(() => {
            const fetchUser = async () => {
            try {
                setTotalBalance(calculateTotal());
            } catch (error) {
                console.log(error);
            }
            };
            fetchUser();
        }, [invoiceData?.recipientId]);

        

        const columns = [
        {
            name: 'Item Description',
            selector: (row) => <span className="text-capitalize">{row.itemDetails}</span>,
            grow: 2,
        },
        {
            name: 'Qty',
            selector: (row) => row.quantity,
            right: true,
        },
        {
            name: 'Rate',
            selector: (row) => row.rate?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            right: true,
        },
        {
            name: 'Amount',
            selector: (row) => <span className="font-weight-bold">{row?.total?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span> ,
            right: true,
        },
        ];

        const data = invoiceData?.lineItems?.map((item) => ({
            itemDetails: item?.itemDetails,
            quantity: item?.quantity,
            rate: item?.rate,
            total: item?.total,
        }));

        const getPaymentStatusStyle = (status) => {
            switch (status.toLowerCase()) {
            case "paid":
                return styles.paid;
            case "partially_paid":
                return styles.partial;
            case "pending":
            default:
                return styles.pending;
            }
        };

    return (
        <Row>
            <Col sm={12} md={12}>
            <Link to='/invoices/all-invoices'><Button>Go Back</Button></Link>
                <Card title={`"Invoice No." - ${invoiceData?.invoiceNumber}`} isOption>
                    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
                        {/* <h6 style={{ textAlign: 'center', marginBottom: '20px' }}>Profit and Loss Overview</h6> */}
                        <div className="flex justify-end mb-4">
                            <PDFDownloadLink
                                document={<InvoicePDF invoiceData={invoiceData} stakeholders={stakeholders} currency={currency} balance={balance} />}
                                fileName={`${invoiceData?.invoiceNumber}.pdf`}
                            >
                                {({ loading }) => (loading ? "Generating PDF..." : <span><img src={downloadImage} alt='Download' style={{ width: '30px', height: 'auto', align: 'center' }} /> Download</span>)}
                            </PDFDownloadLink>
                        </div>

                        <Row className="row grid grid-cols-2 gap-8 mb-4">
                            <Col className="space-y-1">                            
                                <p className="font-weight-bold text-capitalize">SFARMS.CO.KE</p>
                                <p>P.O BOX 800-222</p>
                                <p>Naivasha</p>
                                <p>Phone: 254-787-689-760</p>
                                <p>Email: info@sfarms.co.ke</p>
                            </Col>
                            <Col className="text-right space-y-1 col">
                                <h5 className="text-4xl font-bold text-right mb-8 tracking-wide text-gray-700  text-uppercase">{stakeholders?.type} INVOICE</h5>  
                                <div className="font-bold text-xl text-gray-700">
                                    <img src={avatarImage} alt='Comapny Logo' style={{ width: '100px', height: 'auto', align: 'center' }} />
                                </div>      
                            </Col>
                        </Row>

                        <Row className="row grid grid-cols-2 gap-8 mb-4">
                            <Col>
                                <h5 className="font-weight-bold text-xl text-gray-700 mb-2">Bill To:</h5>
                                <p className="font-weight-bold text-capitalize">{stakeholders?.name}</p>
                                <p className="text-capitalize">{stakeholders?.location}</p>
                                <p>Phone: {stakeholders?.phone}</p>
                                <p>Email: {stakeholders?.email}</p>
                            </Col>
                            
                            <Col className="pace-y-1 text-right">
                                <Row>
                                    <Col>
                                        <p>Invoice#:</p>
                                        <p>Invoice Date:</p>
                                        <p>Due Date:</p>
                                    </Col>
                                    <Col className="pace-y-1 text-left">  
                                        <p><span className="font-weight-bold">{invoiceData?.invoiceNumber}</span></p>
                                        <p><span className="font-weight-bold">{new Date(invoiceData?.invoiceDate).toDateString()}</span></p>
                                        <p><span className="font-weight-bold">{new Date(invoiceData?.dueDate).toDateString()}</span></p>
                                    </Col>                  
                                </Row>
                            </Col>
                        </Row>

                        <Row className="mb-4">
                            <Col>
                                <DataTable
                                    columns={columns}
                                    data={data}
                                    noHeader
                                    responsive
                                    customStyles={{
                                        rows: {
                                            style: {
                                                borderBottom: '1px solid #d1d5db', // Tailwind gray-300
                                            },
                                        },
                                        headCells: {
                                            style: {
                                                backgroundColor: '#f3f4f6', // Tailwind gray-200
                                            },
                                        },
                                    }}
                                />
                            </Col>
                        </Row>

                        <Row>
                        <Col>
                        <p
                        className="text-blue-500 underline mb-8"
                    >
                        + Add Line Item
                    </p>
                        </Col>
                    
                        <Col className="text-right space-y-2 mb-12">
                        <Row>

                        <Col>
                            <p>Sub Total:</p>
                            <p>Paid Amount:</p>
                            <hr />
                            <p className="text-lg font-weight-bold text-c-blue f-16">BALANCE:</p>
                            <hr />
                        </Col>

                        <Col>
                            <p><span className="font-weight-bold">{calculateSubTotal()?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></p>
                            <p><span className="font-weight-bold">{(invoiceData?.paidAmount)?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></p>
                            <hr />
                            <p className="text-lg font-weight-bold text-c-blue f-16"><span>{currency} {calculateTotal()?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></p>
                            <hr />
                        </Col>

                    </Row>
                    </Col>
                        </Row>

                        <Row>
                            <Col sm={12} lg={12} className="mb-12">
                                <h5 className="font-bold text-lg mb-2 text-capitalize">{invoiceData?.type} Notes</h5>
                                <p
                                >{invoiceData?.notes}</p>
                                <hr />
                            </Col>

                            <Col sm={12} lg={12} className="mb-12">
                                <h5 className="font-bold text-lg mb-2">Terms & Conditions</h5>
                                <p
                                >Please make payment by the due date <span className="font-weight-bold">{new Date(invoiceData?.dueDate).toDateString()}</span> , that is in <span className="font-weight-bold">{invoiceData?.terms}</span> day(s).</p>
                                <hr />
                            </Col>
                        </Row>

                    </div>
                </Card>
            </Col>
        </Row>
    );
    };

    const InvoicePDF = ({ invoiceData, stakeholders, currency, balance}) => (
        <Document>
        <Page style={styles.page}>

        <View
            style={[styles.stampContainer]}
            >
            <Text>
                {invoiceData?.paymentStatus === "partially_paid"
                ? "PARTIAL"
                : invoiceData?.paymentStatus?.toUpperCase()}
            </Text>
            </View>

            {/* Header */}
            <Text style={styles.header}>{stakeholders?.type} INVOICE</Text>
    
            {/* Company Details */}
            <View style={styles.rows} fixed>
                <View style={styles.companyInfo}>
                    <Text style={[styles.spacing, styles.bold]}>SFARMS.CO.KE</Text>
                    <Text style={styles.spacing}>P.O BOX 800-222</Text>
                    <Text style={styles.spacing}>Naivasha</Text>
                    <Text style={styles.spacing}>Phone: (+254)-787-689-760</Text>
                    <Text style={styles.spacing}>Email: info@sfarms.co.ke</Text>
                </View>
                <View style={styles.companyInfo}>
                    <Image source={avatarImage} style={styles.letterheadLogo} />
                </View>
            </View>
    
            {/* Client Details */}
            <View style={styles.row}>
            <View style={styles.section}>
            <Text style={[styles.bold, styles.spacing, {color: "#3F6212"}]}>Bill To:</Text>
            <Text style={[styles.spacing, styles.bold, styles.cap]}>{stakeholders?.name || "N/A"}</Text>
            <Text style={[styles.spacing, styles.cap]}>{stakeholders?.location || "N/A"}</Text>
            <Text style={styles.spacing}>Phone: {stakeholders?.phone || "N/A"}</Text>
            <Text style={styles.spacing}>Email: {stakeholders?.email || "N/A"}</Text>
            </View>
            {/* Invoice Details */}
            <View style={styles.section}>
                <View style={styles.rows}>
                    <View style={styles.sections}>
                        <Text style={[styles.spacing, {color: "#6B7280"}]}>Invoice#:</Text>
                        <Text style={[styles.spacing, {color: "#6B7280"}]}>Invoice Date:</Text>
                        <Text style={[styles.spacing, {color: "#6B7280"}]}>Due Date:</Text>
                    </View>
                    <View style={styles.sectionss}>
                        <Text style={[styles.spacing, styles.bold]}>{invoiceData.invoiceNumber || "0000"}</Text>
                        <Text style={[styles.spacing, styles.bold]}>{new Date(invoiceData.invoiceDate).toDateString() || "N/A"}</Text>
                        <Text style={[styles.spacing, styles.bold]}>{new Date(invoiceData.dueDate).toDateString() || "N/A"}</Text>
                    </View>
                </View>
            </View>
            </View>
        
    
            {/* Table */}
            <View>
            {/* Table Header */}
            <View style={styles.tableHeader}>
                <Text style={[styles.tableCell, { flex: 3 }]}>Item Description</Text>
                <Text style={styles.tableCell}>Qty</Text>
                <Text style={styles.tableCell}>Rate</Text>
                <Text style={styles.tableCell}>Amount</Text>
            </View>
    
            {/* Table Rows */}
            {invoiceData.lineItems?.map((item, index) => (
                <View key={index} style={styles.tableRow}>
                <Text style={[styles.tableCell, { flex: 3, color: "#6B7280", textTransform: "capitalize" }]}>
                    {item.itemDetails || "N/A"}
                </Text>
                <Text style={[styles.tableCell, {color: "#6B7280"}]}>{item.quantity || 0}</Text>
                <Text style={[styles.tableCell, {color: "#6B7280"}]}>
                    {item.rate?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || 0}
                </Text>
                <Text style={[styles.tableCell, {color: "#6B7280"}]}>
                    {item.total?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || 0}
                </Text>
                </View>
            ))}
            </View>
    
            {/* Total Section */}
            <View style={styles.row}>
            <View style={styles.totalRow}>
            <Text style={styles.spacing}>+ Add Items</Text>
            </View>
            <View style={styles.totalRow}>
                <View style={styles.row}>
                    <View style={styles.sections}>
                        <Text style={[styles.spacing, {color: "#6B7280"}]}>Sub Total:</Text>
                        <Text style={[styles.spacing, {color: "#6B7280"}]}>Paid Amount:</Text>
                        <Text style={[styles.spacing, {color: "#3F6212"}]}>BALANCE:</Text>
                    </View>
                    <View style={styles.sectionss}>
                        <Text style={[styles.spacing, styles.bold]}>{invoiceData?.totalAmount?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || 0}</Text>
                        <Text style={[styles.spacing, styles.bold]}>{invoiceData?.paidAmount?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || 0}</Text>
                        <Text style={[styles.spacing, styles.bold, {color: "#3F6212"}]}>{currency} {balance?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || 0}</Text>
                    </View>
                </View>
            </View>
            </View>
    
            {/* Notes */}
            <View style={styles.section}>
            <Text style={styles.bold}>Notes:</Text>
            <Text style={[styles.spacing, {color: "#6B7280"}]}>{invoiceData.notes || "No additional notes provided."}</Text>
            </View>

            {/* Notes */}
            <View style={styles.section}>
            <Text style={styles.bold}>Terms & Conditions</Text>
            <Text style={[styles.spacing, {color: "#6B7280"}]}>{`Please make payment by the due date ${new Date(invoiceData?.dueDate).toDateString()}, that is in ${invoiceData.terms} day(s).`}</Text>
            </View>
    
            {/* Footer */}
            <Text style={styles.footer}>Thank you for your business!</Text>
        </Page>
        </Document>
    );
  
    const styles = StyleSheet.create({
        page: {
        padding: 40,
        fontFamily: "Nunito",
        fontSize: 11,
        },
        letterheadLogo: {
            width: 100,
            height: 100,
        },
        header: {
        textAlign: "center",
        fontSize: 18,
        marginBottom: 20,
        fontWeight: "bold",
        color: "#3F6212", // Dark green
        textTransform: "uppercase",
        },
        companyInfo: {
        marginBottom: 20,
        },
        section: {
        marginBottom: 10,
        },
        spacing: {
        marginBottom: 7,
        },
        sections: {
        textAlign: "right",
        marginRight: 20,
        },
        sectionss: {
        textAlign: "left",
        },
        bold: {
        fontWeight: "bold",
        },
        row: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 10,
        },
        rows: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 10,
        },
        tableHeader: {
        backgroundColor: "#F3F4F6", // Tailwind gray-200
        padding: 5,
        flexDirection: "row",
        fontWeight: "bold",
        },
        tableRow: {
        padding: 5,
        flexDirection: "row",
        borderBottom: "1px solid #E5E7EB", // Tailwind gray-300
        },
        tableCell: {
        flex: 1,
        fontSize: 10,
        padding: 2,
        },
        totalRow: {
        marginTop: 20,
        fontWeight: "bold",
        },
        cap: {
        textTransform: "capitalize",
        },
        footer: {
            // position: "absolute",
        marginTop: 20,
        fontSize: 10,
        textAlign: "center",
        color: "#6B7280", // Tailwind gray-500
        },
        stampContainer: {
            position: "absolute",
            top: 40,
            right: 40,
            padding: 10,
            borderRadius: 5,
            borderWidth: 1,
            borderColor: "#333",
            textAlign: "center",
            fontWeight: "bold",
            fontSize: 12,
            transform: "rotate(-15deg)", // Slanted effect
        },
    });

export default Invoice;

