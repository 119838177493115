import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card, Form, Button, Alert, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModuleNotification from '../../../components/Widgets/Statistic/Notification';
import config from '../../../config';
import axios from 'axios';
import { usePermissions } from '../../../permissions';

// import Card from '../../../components/Card/MainCard';

const BasicBreadcrumbPagination = ({ onCancel }) => {
    const account = useSelector((state) => state.account);
    const [data, setData] = useState({});
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const formRef = useRef(null);
    const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
    const apiUrl = config[environment].apiUrl;
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [userRoles, setUserRoles] = useState([]);
    const { createPermission, deletePermission, editPermission } = usePermissions();

    useEffect(() => {
        const fetchUserRoles = async () => {
            try {
                const response = await axios.get(`${apiUrl}/roles`);
                setUserRoles(response.data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchUserRoles();
    }, []);

    // console.log(userRoles);

    const handleInput = (e) => {
        const { name, value } = e.target;

        setData({ ...data, [name]: value, userName: account.user.firstname, userId: account.user.id });
        console.log(data);
    };

    const handleUser = async (e) => {
        e.preventDefault();
        if (!createPermission) {
            return;
        }
        try {
            const response = await axios.post(`${apiUrl}/admins/new`, data);

            if (response.data) {
                setTimeout(() => {
                    setSuccess(null);
                }, 5000);
                toast.success(response.data.message);
                setSuccess(response.data.message);
                formRef.current.reset();
                setData({});
                console.log(response);
                onCancel();
            }
        } catch (error) {
            // setError(error.message);
            // setTimeout(() => {
            //     setError(null);
            // }, 5000);
            console.log(error.message);
            if (error.response && error.response.status === 400) {
                setError(`${error.response.data.message}`);
                toast.error(`${error.response.data.message}`);
                // setShowConfirmationModal(false);
            } else {
                setError('An error occurred. Please try again later.');
                // setShowConfirmationModal(false);
            }
        }
    };

    const handlePhoneChange = (event) => {
        let inputPhoneNumber = event.target.value;

        // Remove non-numeric characters
        const sanitizedPhoneNumber = inputPhoneNumber.replace(/\D/g, '');

        // Ensure that the phone number starts with the country code (+254)
        if (!sanitizedPhoneNumber.startsWith('254')) {
            // Prepend country code if it's not already present
            inputPhoneNumber = '254' + sanitizedPhoneNumber;
        }

        // Format the phone number into three segments: (+254) xxx-xxx-xxxx
        const countryCode = '+254';
        const areaCode = sanitizedPhoneNumber.substring(3, 6);
        const firstPart = sanitizedPhoneNumber.substring(6, 9);
        const secondPart = sanitizedPhoneNumber.substring(9, 12);
        const formattedPhoneNumber = `(${countryCode}) ${areaCode}-${firstPart}-${secondPart}`;

        // Update the state with the formatted phone number
        setPhoneNumber(formattedPhoneNumber);

        // Pass the formatted phone number to the parent component
        handleInput({ target: { name: 'phone_number', value: formattedPhoneNumber } });
    };

    const handleEmailChange = (event) => {
        const inputEmail = event.target.value;

        // Check if the entered email matches the correct format using regex
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputEmail);

        // Update the state with the entered email
        setEmail(inputEmail);

        // Pass the email to the parent component only if it's valid
        if (isValidEmail) {
            handleInput({ target: { name: 'email', value: inputEmail } });
        }
    };

    return (
        <React.Fragment>
            <Modal show={true} onHide={onCancel} size="xl" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={12}>
                            {success && (
                                <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
                                    {success}
                                </Alert>
                            )}
                            {error && (
                                <Alert variant="danger" onClose={() => setError(null)} dismissible>
                                    {error}
                                </Alert>
                            )}
                            <ToastContainer />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Form onSubmit={handleUser} ref={formRef}>
                                <Row>
                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasicName">
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control
                                            required
                                            onChange={handleInput}
                                            type="text"
                                            placeholder="Enter First Name"
                                            name="firstname"
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasicName">
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control
                                            required
                                            onChange={handleInput}
                                            type="text"
                                            placeholder="Enter Last Name"
                                            name="lastname"
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasicEmail">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            required
                                            onChange={handleEmailChange}
                                            value={email}
                                            type="email"
                                            placeholder="example@gmail.com"
                                            name="email"
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasicPhone">
                                        <Form.Label>Phone Number</Form.Label>
                                        <Form.Control
                                            required
                                            onChange={handlePhoneChange}
                                            value={phoneNumber}
                                            type="tel"
                                            placeholder="e.g., +254 726 635 232"
                                            name="phone_number"
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasicID">
                                        <Form.Label>ID Number</Form.Label>
                                        <Form.Control
                                            required
                                            onChange={handleInput}
                                            type="number"
                                            placeholder="36544322"
                                            name="id_number"
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} sm={12} lg={6}>
                                        <Form.Label>Address</Form.Label>
                                        <Form.Control required onChange={handleInput} type="address" name="address" />
                                    </Form.Group>

                                    {/* <Form.Group as={Col} md={6} sm={12} lg={6}>
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control
                                            required
                                            onChange={handleInput}
                                            type="password"
                                            placeholder="Password"
                                            name="password"
                                        />
                                    </Form.Group> */}

                                    <Form.Group as={Col} md={6} sm={12} lg={6}>
                                        <Form.Label>User Role:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            as="select"
                                            size="sm"
                                            name="is_admin"
                                            onChange={handleInput}
                                            required
                                            // disabled={disabled} // Assuming disabled is a variable controlling the disabled state
                                        >
                                            <option value=""> </option>
                                            {Array.isArray(userRoles) &&
                                                userRoles.map((role) => (
                                                    <option key={role.id} value={role.name}>
                                                        {role.name}
                                                    </option>
                                                ))}
                                        </Form.Control>
                                    </Form.Group>
                                </Row>
                                <Modal.Footer>
                                    {createPermission && <Button type="submit">Submit</Button>}
                                    <Button variant="secondary" onClick={onCancel}>
                                        Cancel
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};

export default BasicBreadcrumbPagination;
