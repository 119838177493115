import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import config from "../../config";
import { usePermissions } from "../../permissions";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import ConfirmationModal from "../ui-elements/basic/ConfirmationModal";

import Select from "react-select";
import { toast } from "react-toastify";
import InvoiceFormModal from "../ui-elements/basic/InvoiceFormModal";
import { useSelector } from "react-redux";
// import InvoiceFormModal from "./InvoiceFormModal";

const TransactionsTable = () => {
  const account = useSelector((state) => state.account);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { createPermission, deletePermission, editPermission } =
    usePermissions();
  const [searchText, setSearchText] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [action, setAction] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const environment =
    window.location.hostname === "localhost" ? "development" : "production";
  const apiUrl = config[environment].apiUrl;
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [paidBy, setPaidBy] = useState(account?.user?.id);

  const handleCreateInvoice = () => {
    if (selectedRows.length === 0) {
      toast.error(
        "Please select at least one transaction to create an invoice.",
      );
      return;
    }
    setIsInvoiceModalOpen(true);
  };

  const paymentStatusOptions = [
    { value: "fully_paid", label: "Fully Paid" },
    // { value: 'partially_paid', label: 'Partially Paid' },
    { value: "unpaid", label: "Unpaid" },
  ];

  const actionOptions = [
    { value: "update-payment-status", label: "Update Payment Status" },
    { value: "confirm-transactions", label: "Confirm Transactions" },
    { value: "create-invoice", label: "Create Invoice" }, // New action
    // { value: 'update-payment', label: 'Update Payment' }
  ];

  const handleRowSelection = (id) => {
    setSelectedRows((prev) =>
      prev.includes(id) ? prev.filter((row) => row !== id) : [...prev, id],
    );
  };

  useEffect(() => {
    fetchTransactions();
  }, []);

  // Fetch transactions from the API
  const fetchTransactions = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiUrl}/api/transactions`);
      setTransactions(response.data.transactions);
      console.log(response.data.transactions);
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch transactions");
      setLoading(false);
    }
  };

  const onSuccess = () => {
    fetchTransactions();
  };

  const handleActionChange = (selectedOption) => {
    setAction(selectedOption);
    if (selectedOption?.value === "update-payment-status") {
      setSelectedStatus(null);
    }
    if (selectedOption?.value === "create-invoice") {
      if (selectedRows.length === 1) {
        // Handle single invoice creation
        // openInvoiceModal(selectedRows[0]); // Open modal for the single transaction
      } else if (selectedRows.length > 1) {
        // Handle bulk invoice creation
        // handleBulkInvoiceCreation(selectedRows);
      }
    } else {
      setIsModalOpen(true);
    }
  };

  const handleBulkUpdate = async () => {
    if (selectedRows.length === 0) {
      alert("No rows selected for update.");
      return;
    }

    if (!action) {
      alert("Please select an action.");
      return;
    }

    if (action.value === "update-payment-status" && !selectedStatus?.value) {
      alert("Please select payment status.");
      return;
    }

    setShowConfirmationModal(true);
  };

  const updatedRows = selectedRows.map((row) => ({
    ...row,
    paidBy: paidBy, // Add paidBy to each row
  }));

  const handleConfirmation = async () => {
    setIsLoading(true);

    try {
      const payload = {
        action: action.value,
        paymentStatus:
          action.value === "update-payment-status"
            ? selectedStatus?.value
            : undefined,
        rows: updatedRows,
        // rows: {selectedRows, paidBy: paidBy},
      };

      const response = await axios.put(
        `${apiUrl}/api/transactions/bulk-update`,
        payload,
      );

      toast.success(response.data.message || "Bulk update successful.");
      setTransactions((prev) =>
        prev.map((transaction) =>
          selectedRows.some((row) => row.id === transaction.id)
            ? { ...transaction, ...response.data.updatedFields }
            : transaction,
        ),
      );
      setSelectedRows([]);
      setAction("");
      setSelectedStatus(null);
    } catch (error) {
      console.log("Error updating transactions:", error);
      toast.error("An error occurred while processing the update.");
    } finally {
      setIsLoading(false);
      setShowConfirmationModal(false);
      fetchTransactions();
    }
  };

  const handleCancelConfirmation = () => {
    // Handle cancel action if needed
    setShowConfirmationModal(false);
  };

  // Define table columns
  const columns = [
    {
      name: "TimeSTamp",
      selector: (row) => row.createdAt,
      sortable: true,
      format: (row) =>
        row.createdAt ? new Date(row.createdAt).toLocaleString() : "null", // Format date
    },
    {
      name: "Transaction ID",
      selector: (row) => row.transactionId,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.paymentStatus,
      sortable: true,
    },
    {
      name: "Due Date",
      selector: (row) => row.dueDate,
      sortable: true,
      format: (row) =>
        row.dueDate ? new Date(row.dueDate).toLocaleDateString() : "null", // Format date
    },
    {
      name: "Confirmed",
      selector: (row) => row.confirmDate,
      sortable: true,
      format: (row) =>
        row.confirmDate
          ? new Date(row.confirmDate).toLocaleDateString()
          : "null", // Format date
    },
    {
      name: "To Pay(KES)",
      selector: (row) => row.toPay,
      sortable: true,
      format: (row) =>
        row.toPay
          ? `${row.toPay.toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })}`
          : "0.00", // Format amount
    },
    {
      name: "Paid Amount(KES)",
      selector: (row) => row.paidAmount,
      sortable: true,
      format: (row) =>
        row.paidAmount
          ? `${row.paidAmount.toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })}`
          : "0.00", // Format amount
    },
  ];

  const filteredData = searchText
    ? transactions?.filter((item) =>
        Object.values(item).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchText.toLowerCase()),
        ),
      )
    : transactions;

  // Add unique keys dynamically to ensure there are no duplicate keys
  const dataWithUniqueKeys = filteredData?.map((item, index) => ({
    ...item,
    uniqueKey: `${item.createdAt || "defaultKey"}-${index}`, // Combine `createdAt` and index
  }));

  return (
    <div>
      <Row>
        <Col sm={12}>
          <span>Bulk Actions:</span>
          <Row>
            <Col sm={3}>
              {transactions && transactions.length > 0 && (
                <Row>
                  <Form.Group
                    as={Col}
                    md={12}
                    sm={12}
                    lg={12}
                    controlId="formBasicName"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Search..."
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </Form.Group>
                </Row>
              )}
            </Col>
            {selectedRows.length > 0 && (
              <Col sm={3}>
                <div>
                  {/* <label>Select Action:</label> */}
                  <Select
                    options={actionOptions}
                    value={action}
                    onChange={handleActionChange}
                    placeholder="Choose an action..."
                  />
                </div>
              </Col>
            )}

            {action?.value === "update-payment-status" && (
              <Col sm={3}>
                <div>
                  <Select
                    options={paymentStatusOptions}
                    value={selectedStatus}
                    onChange={setSelectedStatus}
                    placeholder="Choose status..."
                  />
                </div>
              </Col>
            )}

            {/* {action?.value === 'update-payment' && (
                        <Col sm={3}>
                                <div>
                                    <Form.Control
                                        size="sm"
                                        required
                                        // onChange={(e) => handleInput('amount', e.target.value)}
                                        type="number"
                                        name="amount"
                                    />
                                </div>
                        </Col>
                            )} */}

            {action.value && action.value !== "create-invoice" && (
              <Col sm={3}>
                <Button
                  onClick={handleBulkUpdate}
                  disabled={isLoading || selectedRows.length === 0}
                >
                  {isLoading ? "Updating..." : action?.label}
                </Button>
              </Col>
            )}

            {action.value && action.value === "create-invoice" && (
              <Col sm={3}>
                <Button
                  onClick={() => setShow(true)}
                  disabled={isLoading || selectedRows.length !== 1}
                >
                  {isLoading ? "Updating..." : action?.label}
                </Button>
              </Col>
            )}
          </Row>
          <hr />
        </Col>

        <Col sm={12}>
          <DataTable
            // title="Transaction List"
            columns={columns}
            data={dataWithUniqueKeys}
            keyField="uniqueKey"
            progressPending={loading}
            pagination
            highlightOnHover
            striped
            dense
            bordered
            paginationPerPage={20}
            paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
            paginationComponentOptions={{
              rowsPerPageText: "Rows per page:",
            }}
            paginationResetDefaultPage={true}
            paginationTotalRows={transactions.length}
            // expandableRows
            selectableRows
            // onSelectedRowsChange={(selectedRows) => console.log(selectedRows)}
            onSelectedRowsChange={(state) =>
              setSelectedRows(state.selectedRows)
            }
            customStyles={{
              headRow: {
                style: {
                  backgroundColor: "#279e6c", // Green background
                  color: "#fff", // White text
                  textTransform: "uppercase", // Uppercase text
                  // fontSize: '10px', // Adjust font size for header row
                  minHeight: "1.5rem",
                  paddingTop: "8px",
                  paddingBottom: "8px",
                },
              },
            }}
          />
        </Col>
      </Row>
      <ConfirmationModal
        show={showConfirmationModal}
        onHide={handleCancelConfirmation}
        onConfirm={handleConfirmation}
      />
      <InvoiceFormModal
        show={show}
        onHide={() => setShow(false)}
        transactionData={selectedRows}
        onSuccess={onSuccess}
      />
      +
    </div>
  );
};

export default TransactionsTable;
