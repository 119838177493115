import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { Row, Col, Form, Button, Alert, Table } from 'react-bootstrap';
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, PDFViewer, Font, Image } from '@react-pdf/renderer';
import config from './../../../config';
import axios from 'axios';
import Card from '../../../components/Card/MainCard';
import MontserratRegular from './../../../fonts/static/Montserrat-Regular.ttf';
import MontserratBold from './../../../fonts/static/Montserrat-Bold.ttf';
import InvoicePage from '../../../components/InvoicePage';

Font.register({
  family: 'Montserrat',
  fonts: [
    {
      src: MontserratRegular,
      fontWeight: 'normal',
    },
    {
      src: MontserratBold,
      fontWeight: 'bold',
    },
    // Register other font weights if needed
  ],
});

const InvoiceDefault = () => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [data, setData] = useState({});
  const [loan, setLoan] = useState({});
  const [loanRepayments, setLoanRepayments] = useState([]);
  const { id, loanId } = useParams();
  const history = useHistory();
  const environment = window.location.hostname === 'localhost' ? 'development' : 'production';
  const apiUrl = config[environment].apiUrl;
  const [pdfBlob, setPdfBlob] = useState(null);

  const [invoice, setInvoice] = useState(null);
  const [lineItems, setLineItems] = useState([]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/invoices/${id}`);
        const data = response.data;
        console.log(data)
        setInvoice(data.invoice);
        setLineItems(data.lineItems);
        const combined = {...data.invoice, lineItems: data.lineItems}
        window.localStorage.setItem('invoiceData', JSON.stringify(combined))
        setData(combined)
      } catch (error) {
        console.log(error);
      }
    };
    fetchUser();
  }, [id]);

  const onInvoiceUpdated = (invoice) => {
    window.localStorage.setItem('invoiceData', JSON.stringify(invoice))
  }

  return (
    <div className="app">     
      <InvoicePage invoiceData={data} onChange={onInvoiceUpdated} />
    </div>
  )
}
export default InvoiceDefault;
